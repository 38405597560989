import React from 'react'
import { Container } from 'components/ui'
import Advatanges from '../components/advantages/container/Advatanges'
import { GetInTouch } from 'components/pages'
import Carousel from '../components/carousel/container/Carousel'
import ScrollToTopWithTransition from 'utils/ScrollToTopWithTransition'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const Cooperation = () => {
  // Using useInView to trigger animations on scroll
  const [advatangesRef, advatangesInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const [carouselRef, carouselInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const [getInTouchRef, getInTouchInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })

  return (
    <div style={{ overflow: 'hidden' }}>
      <ScrollToTopWithTransition />

      {/* Advatanges Section - Slide from Left when in view */}
      <motion.div
        ref={advatangesRef}
        initial={{ opacity: 0, y: -100 }}
        animate={advatangesInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        <Advatanges />
      </motion.div>

      <Carousel />

      {/* Get In Touch Section - Slide from Bottom when in view */}
      <motion.div
        ref={getInTouchRef}
        initial={{ opacity: 0, y: 50 }}
        animate={getInTouchInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        <GetInTouch />
      </motion.div>
    </div>
  )
}

export default Cooperation

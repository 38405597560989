import { ApiPaging, ApiResponse, paramApi } from 'common/types/common'
import { ICreateOrder, IOrder } from 'common/types/order'
import { Api } from '../api'

export class OrderApi extends Api {
  async create(payload: ICreateOrder) {
    return await this.execute<ApiResponse<IOrder>>(
      'post',
      `order/create`,
      payload,
      null,
      undefined
    )
  }

  async getOrders(payload: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<IOrder[]>>>(
      'post',
      `order/paging`,
      payload,
      null,
      undefined
    )
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<IOrder>>(
      'get',
      `order/get-by-id/${id}`,
      null,
      null,
      undefined
    )
  }
}

import { Modal } from 'antd'
import styled from 'styled-components'

export const StyledCategoriesModal = styled(Modal)`
  width: 90% !important;

  & .ant-modal-content {
    padding: 0;
    margin: 0;
    /* overflow: hidden; */
    border-radius: 0;
    /* width: 90%; */
  }
  & .ant-modal-body {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: calc(100vh - 300px);
    overflow: hidden;
  }
  & .side-navigation {
    display: flex;
    list-style-type: none;
    flex-direction: column;
    min-width: 300px;
    max-width: 100%;
    height: 100%;
    background-color: rgba(245, 245, 245, 1);
  }

  & .side-navigation li {
    padding: 15px 20px;
    background-color: rgba(245, 245, 245, 1);
    cursor: pointer;
  }

  & .side-navigation li:hover,
  & .side-navigation li.active {
    background-color: rgb(240, 240, 240);
  }

  & .side-navigation li p {
    text-transform: uppercase;
  }

  & .side-navigation-content {
    padding: 15px;
    height: 100%;
    overflow: hidden;
    flex-grow: 1;
  }

  @media screen and (max-width: 767px) {
    width: 100% !important;
    & .side-navigation {
      width: 100%;
      flex-direction: row;
      height: auto;
      flex-wrap: wrap;
    }
    & .ant-modal-body {
      flex-direction: column !important;
    }
  }
`

export const StyledSearchModal = styled(Modal)`
  /* width: 90% !important; */
  background-color: transparent;

  & .ant-modal-content {
    padding: 0;
    margin: 0;
    background-color: transparent;
  }
  & .ant-modal-body {
  }

  & .search-results {
    margin-top: 20px;
    background-color: white;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
    overflow: hidden;
  }

  & .search-results a {
    padding: 10px 15px;
    display: block;
    color: black;
    transition: all 0.3s ease-in-out;
  }

  & .search-results a:hover {
    background-color: rgba(240, 240, 240);
  }

  & .search-input input {
    font-family: 'Nunito', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }

  @media screen and (max-width: 767px) {
    width: 100% !important;
    & .ant-modal-body {
    }
  }
`

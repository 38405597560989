import { Col, Row } from 'antd'
import { Filter } from 'components/form'
import { IField } from 'components/form/filter/Filter'
import { GetInTouch } from 'components/pages'
import { Container, Loading, ProductCard } from 'components/ui'
import { useTranslation } from 'react-i18next'
import useLooksContext from '../services/looksContext'
import '../styles/Looks.css'
import CategoryHeader from '../components/CategoryHeader'
import { IProduct } from 'common/types/products'

const Looks = () => {
  const {
    state: { data, loading }
  } = useLooksContext()
  return (
    <div>
      {loading ? (
        <Loading mode="dark" />
      ) : (
        <>
          <CategoryHeader />
          <Container size="xxl">
            <div style={{ margin: '0 0 50px 0' }}>
              {/* <h3 className="jetbrains-mono" style={{ marginBottom: '20px' }}>
                  {category.name['uz']}
                </h3> */}
              <Row gutter={[20, 20]}>
                {data?.products?.map((product: IProduct) => (
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <ProductCard {...product} />
                  </Col>
                ))}
              </Row>
            </div>
          </Container>
        </>
      )}
      <GetInTouch />
    </div>
  )
}

export default Looks

import React from 'react'
import usePurchasesContext from '../services/purchasesContext'
import { Loading, StatusTag } from 'components/ui'
import notFound from 'assets/images/notfound.svg'
import { Flex, Space, Spin, Tag } from 'antd'
import moment from 'moment'
import { format } from 'utils/format'
import { Link } from 'react-router-dom'
import useLayoutContext from 'components/ui/layout/services/layoutContext'
import { useTranslation } from 'react-i18next'

const Purchases = () => {
  const { t } = useTranslation()
  const {
    state: { loading, orders }
  } = usePurchasesContext()

  const {
    state: { currency }
  } = useLayoutContext()

  return (
    <div>
      <h1 style={{ fontFamily: "'Nunito', sans-serif" }}>
        {t('profile.my_orders.title')}
      </h1>
      {loading ? (
        <Flex justify="center" align="center" style={{ height: '300px' }}>
          <Spin />
        </Flex>
      ) : orders && orders?.length > 0 ? (
        <Space
          styles={{ item: { width: '100%' } }}
          style={{ width: '100%', marginTop: 20 }}
          direction="vertical"
        >
          {orders.map((order) => (
            <Flex
              justify="space-between"
              style={{
                border: '1px solid rgba(233, 233, 233, 1)',
                padding: 15
              }}
              gap={10}
            >
              <Space direction="vertical">
                <Link to={`/order/info/${order._id}`}>
                  <p style={{ color: 'rgba(11, 153, 255, 1)' }}>
                    {t('profile.my_orders.order')} {order.number}
                  </p>
                </Link>
                <p style={{ color: 'rgba(153, 153, 153, 1)' }}>
                  {moment(order.created_at).format('MM/D/YYYY | h:mm:ss')}
                </p>
              </Space>
              <Space>
                <p>{format.money(order.total_price, currency)}</p>
                <StatusTag status={order.state} />
              </Space>
            </Flex>
          ))}
        </Space>
      ) : (
        <div style={{ padding: '30px 0', textAlign: 'center' }}>
          <img src={notFound} width={150} />
          <p>{t('profile.my_orders.not_found')}</p>
        </div>
      )}
    </div>
  )
}

export default Purchases

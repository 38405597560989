import { Button, Container } from 'components/ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from 'antd'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCards } from 'swiper/modules'
import 'swiper/css/effect-cards'
import useCategoriesContext from '../services/categoriesContext'
import { format } from 'utils/format'
import useLayoutContext from 'components/ui/layout/services/layoutContext'
import useConverter from 'hooks/useConverter'

const Collection = () => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const language = i18n.language as 'uz' | 'ru' | 'en'

  const { converter } = useConverter()

  const {
    state: { collections, loading }
  } = useCategoriesContext()

  const {
    state: { currency }
  } = useLayoutContext()

  return collections ? (
    <div
      className="collection"
      style={{
        backgroundImage: `url(${process.env.REACT_APP_FILE_URL}${collections?.collection_background?.source_url})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <Container size="xxl">
        <Flex wrap justify="space-between" gap={50}>
          <div>
            <h1 className="cormorant-title" style={{ marginBottom: 20 }}>
              {collections?.collection_name?.[language]}
            </h1>
            {/* <Button type="contained">
              <span>{t('home.collections.button')}</span>
            </Button> */}
          </div>
          <div className="collection-swiper-wrapper">
            <Swiper
              effect={'cards'}
              grabCursor={true}
              modules={[EffectCards]}
              className="collection-swiper"
              style={{
                width: '330px',
                height: '450px',
                overflow: 'visible',
                maxWidth: '100%'
              }}
            >
              {collections?.collections?.map((collection) => (
                <SwiperSlide className="collection-swiper-slide">
                  <img
                    src={`${process.env.REACT_APP_FILE_URL}${collection?.main_image?.source_url}`}
                    alt={collection?.main_image?.alt}
                  />
                  <div>
                    <p>{collection?.name?.[language]}</p>{' '}
                    <p>
                      {format.money(
                        converter(
                          collection.integration.price,
                          collection.integration.currency_id,
                          currency
                        ),
                        currency
                      )}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Flex>
      </Container>
    </div>
  ) : null
}

export default Collection

import { Form, FormProps, Select, SelectProps } from "antd";
import { useState } from "react";

interface IOption {
  label: string;
  value: string;
}

interface IMultipleSelect {
  label?: string;
  placeholder?: string;
  options: IOption[];
  form?: FormProps["form"];
  name?: string;
  onSelect?: (value: any) => void;
}

const MultipleSelect = ({
  label,
  placeholder = "Tanlang",
  options: initialOptions,
  form,
  name,
  onSelect,
}: IMultipleSelect) => {
  const [options, setOptions] =
    useState<SelectProps["options"]>(initialOptions);
  const handleChange = (value: string[]) => {
    if (form && name) form?.setFieldValue(name, value || undefined);
    if (onSelect) onSelect(value || undefined);
  };
  return (
    <Form.Item name={name} label={label} style={{ margin: 0 }}>
      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder={placeholder}
        onChange={handleChange}
        options={options}
      />
    </Form.Item>
  );
};

export default MultipleSelect;

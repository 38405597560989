import { ApiResponse } from 'common/types/common'
import { ICurrency } from 'common/types/currency'
import { Api } from '../api'

export class CurrencyApi extends Api {
  async getAll() {
    return await this.execute<ApiResponse<ICurrency[]>>(
      'get',
      `currency/get-all`,
      null,
      null,
      undefined
    )
  }
}

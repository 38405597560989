import React from 'react'
import { LooksContextProvider } from '../services/looksContext'
import Looks from './Looks'

const index = () => {
  return (
    <LooksContextProvider>
      <Looks />
    </LooksContextProvider>
  )
}

export default index

import { ApiResponse } from 'common/types/common'
import { Api } from '../api'
import { IUser } from 'common/types/user'

export class UserApi extends Api {
  async otpSend(payload: { phone_number: string }) {
    return await this.execute<ApiResponse<any>>(
      'post',
      `user/otp/send`,
      payload,
      null,
      undefined
    )
  }

  async otpCheck(payload: { _id: string; code: number }) {
    return await this.execute<ApiResponse<any>>(
      'post',
      `user/otp/check`,
      payload,
      null,
      undefined
    )
  }

  async updateProfile(payload: IUser) {
    return await this.execute<ApiResponse<IUser>>(
      'put',
      `user/update`,
      payload,
      null,
      undefined
    )
  }

  async getProfile() {
    return await this.execute<ApiResponse<any>>(
      'get',
      `user/profile`,
      null,
      null,
      undefined
    )
  }
}

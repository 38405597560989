import type { FormProps } from 'antd'
import { ChangeEvent, useEffect, useState } from 'react'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/high-res.css'
import styled from 'styled-components'

const StyledPhoneInput = styled(PhoneInput)`
  .selected-flag {
    border-radius: 6px 0 0 6px !important;
  }
  font-family: 'Nunito', sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  & input {
    font-family: 'Nunito', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }
  & .country-list li span {
    line-height: 0;
  }
`

interface IPhoneNumberInput {
  form: FormProps['form']
  name: string
  defaultValue?: string
  onChange?: (
    value: string,
    data: CountryData | {},
    event: ChangeEvent<HTMLInputElement>,
    formattedValue: string,
    isCompleted: boolean
  ) => void
  [key: string]: any
}

const PhoneNumberInput = ({
  name,
  onChange,
  form,
  defaultValue = '',
  ...props
}: IPhoneNumberInput) => {
  const [value, setValue] = useState(defaultValue)
  const formValues = form?.getFieldValue(name)

  useEffect(() => {
    if (formValues) setValue(formValues)
  }, [formValues])

  useEffect(() => {
    if (form) form?.setFieldValue(name, defaultValue)
  }, [])

  const handleChange = (
    value: string,
    data: CountryData,
    event: ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    const isCompleted =
      data?.format.replaceAll(' ', '').length ===
      formattedValue?.replaceAll(' ', '').length
    if (form) form?.setFieldValue(name, value)
    if (onChange) onChange(value, data, event, formattedValue, isCompleted)
  }
  return (
    <StyledPhoneInput
      value={value}
      onChange={handleChange}
      country={'uz'}
      inputClass="ant-input ant-input-lg ant-input-outlined"
      containerStyle={{
        borderRadius: '6px'
      }}
      inputStyle={{
        width: '100%',
        backgroundColor: '#fff',
        border: '1px solid #d9d9d9',
        height: '32px',
        borderRadius: '6px',
        overflow: 'hidden',
        fontSize: '14px'
      }}
      buttonStyle={{
        borderRadius: '6px 0 0 6px'
      }}
      dropdownStyle={{
        border: 'none',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        marginTop: '10px',
        borderRadius: '6px',
        height: '200px'
      }}
      placeholder=""
      {...props}
    />
  )
}

export default PhoneNumberInput

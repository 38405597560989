import api from 'common/api'
import { IOrder } from 'common/types/order'
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'

const Context = () => {
  const [orders, setOrders] = useState<IOrder[]>()
  const [loading, setLoading] = useState(false)

  const getOrders = async () => {
    try {
      setLoading(true)
      const response = await api.order.getOrders({ limit: 200, page: 1 })
      setOrders(response.data.data)
      setLoading(false)
      console.log(response)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    getOrders()
  }, [])
  return {
    state: { orders, loading },
    actions: {}
  }
}

const PurchasesContext = createContext<any>({ state: {}, actions: {} })

export const PurchasesContextProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const value = Context()
  return (
    <PurchasesContext.Provider value={value}>
      {children}
    </PurchasesContext.Provider>
  )
}

export default function usePurchasesContext() {
  return useContext<ReturnType<typeof Context>>(PurchasesContext)
}

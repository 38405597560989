import { Tag } from 'antd'
import { ORDER_STATE } from 'common/types/order'
import { useTranslation } from 'react-i18next'

const StatusTag = ({ status }: { status: ORDER_STATE | undefined }) => {
  const { t } = useTranslation()
  switch (status) {
    case ORDER_STATE.NEW:
      return <Tag color="success">{t('common.status.new')}</Tag>
    case ORDER_STATE.ACCEPTED:
      return <Tag color="success">{t('common.status.accepted')}</Tag>
    case ORDER_STATE.CANCELED:
      return <Tag color="error">{t('common.status.canceled')}</Tag>
    case ORDER_STATE.IN_PROGRESS:
      return <Tag color="warning">{t('common.status.in_progress')}</Tag>
    case ORDER_STATE.ON_THE_WAY:
      return <Tag color="blue">{t('common.status.on_the_way')}</Tag>
    default:
      return <></>
  }
}

export default StatusTag

import React from 'react'
import Layout from './Layout'
import { LayoutContextProvider } from '../services/layoutContext'

const index = () => {
  return (
    <LayoutContextProvider>
      <Layout />
    </LayoutContextProvider>
  )
}

export default index

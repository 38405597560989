import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button as AntButton, Col, Flex, Grid, Row } from 'antd'
import { Container } from 'components/ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import useHomeContext from '../services/homeContext'

const { useBreakpoint } = Grid

const Videos = () => {
  const { t } = useTranslation()
  const [swiperRef, setSwiperRef] = useState<SwiperCore | null>(null)
  const [active, setActive] = useState(0)

  const screens = useBreakpoint()

  const next = () => {
    swiperRef?.slideNext()
  }

  const prev = () => {
    swiperRef?.slidePrev()
  }

  const handleSlideNext = (swiper: SwiperCore) => {
    setActive(swiper.activeIndex)
  }

  const {
    state: { data }
  } = useHomeContext()

  return data ? (
    <div className="videos">
      <Container size="xxl" style={{ overflow: 'visible' }}>
        <Row justify="space-between" gutter={[20, 50]}>
          <Col sm={24} md={6}>
            <Flex
              className="videos-text"
              vertical
              justify="space-between"
              style={{ height: '100%' }}
            >
              <div>
                <h1
                  className="cormorant-title"
                  style={{
                    marginBottom: 10,
                    textTransform: 'uppercase'
                  }}
                >
                  {t('home.videos.title')}
                </h1>
                {/* <Button type="outlined-gradient">
                  <span>{t('home.get_the_look.button')}</span>
                </Button> */}
              </div>

              {!screens.xs && (
                <Flex
                  className="videos-swiper-actions"
                  justify="flex-end"
                  gap={10}
                >
                  <AntButton
                    icon={<FontAwesomeIcon icon={faChevronLeft} />}
                    onClick={() => prev()}
                    shape="circle"
                    size="large"
                  />
                  <AntButton
                    icon={<FontAwesomeIcon icon={faChevronRight} />}
                    onClick={() => next()}
                    shape="circle"
                    size="large"
                  />
                </Flex>
              )}
            </Flex>
          </Col>
          <Col sm={24} md={18}>
            <div style={{ width: '100%' }}>
              <Swiper
                onSwiper={setSwiperRef}
                onSlideChange={handleSlideNext}
                slidesPerView={2.5}
                spaceBetween={30}
                pagination={{
                  clickable: true
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1.3,
                    spaceBetween: 20
                  },
                  790: {
                    slidesPerView: 2.5,
                    spaceBetween: 30
                  }
                }}
                // centeredSlides={true}
                grabCursor={true}
                // modules={[Pagination]}
                className="videos-swiper"
              >
                {data?.videos.map((item) => (
                  <SwiperSlide className="videos-swiper-item">
                    <video
                      src={`${process.env.REACT_APP_FILE_URL}${item.source_url}`}
                      controls
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="videos-swiper-placeholder">
                <div
                  className="videos-swiper-placeholder-active"
                  style={{
                    width: `${((active + 2) / (swiperRef?.slides?.length ?? 1)) * 100}%`,
                    maxWidth: '100%'
                  }}
                ></div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  ) : null
}

export default Videos

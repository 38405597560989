import { CategoryApi } from './modules/category'
import { ColorApi } from './modules/color'
import { HomeApi } from './modules/home'
import { UserApi } from './modules/user'
import { ProductApi } from './modules/product'
import { SizeApi } from './modules/size'
import { OrderApi } from './modules/order'
import { ContactsApi } from './modules/contacts'
import { CurrencyApi } from './modules/currency'

const api = {
  home: new HomeApi(),
  category: new CategoryApi(),
  product: new ProductApi(),
  color: new ColorApi(),
  size: new SizeApi(),
  user: new UserApi(),
  order: new OrderApi(),
  contacts: new ContactsApi(),
  currency: new CurrencyApi()
}

export default api

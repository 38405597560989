import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import i18n from 'i18n'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const lng = localStorage.getItem('language') || 'uz'

i18n.changeLanguage(lng).then(() => root.render(<App />))

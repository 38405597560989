import { Container } from 'components/ui'
import YouTube from 'react-youtube'
import styled from 'styled-components'

const StyledYoutube = styled(YouTube)`
  iframe {
    width: 100%;
    height: 650px;
  }

  @media screen and (max-width: 700px) {
    iframe {
      height: 390px;
    }
  }
`

const VideoContent = () => {
  return (
    <div style={{ backgroundColor: 'black', marginBottom: 50 }}>
      <Container size="xxl">
        <StyledYoutube videoId="-PerPSu8hvk" />
      </Container>
    </div>
  )
}

export default VideoContent

import animationBlack from 'assets/lotties/black-loading.json'
import animationWhite from 'assets/lotties/white-loading.json'
import Lottie from 'lottie-react'
import { useEffect, useState } from 'react'
import ScrollToTopWithTransition from 'utils/ScrollToTopWithTransition'

const Loading = ({
  mode,
  fullscreen = false,
  height
}: {
  mode: 'dark' | 'light'
  fullscreen?: boolean
  height?: string
}) => {
  const [active, setActive] = useState(true)
  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    if (fullscreen) {
      setActive(true)
      document.body.classList.add('no-scroll')

      const timer = setTimeout(() => {
        document.body.classList.remove('no-scroll')
        setActive(false)
      }, 3000)

      return () => {
        setActive(false)
        clearTimeout(timer)
        document.body.classList.remove('no-scroll')
      }
    }
  }, [])

  useEffect(() => {
    // Function to update scroll position state
    const handleScroll = () => {
      setScrollY(window.scrollY)
    }

    // Attach scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return active ? (
    <div
      style={{
        backgroundColor: mode === 'dark' ? 'white' : 'black',
        width: '100%',
        height: height
          ? height
          : mode === 'dark'
            ? `calc(100vh - 200px)`
            : `calc(100vh - 200px)`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...(fullscreen && {
          position: 'absolute',
          top: scrollY,
          left: 0,
          height: '100vh',
          zIndex: 999999
        })
      }}
    >
      <ScrollToTopWithTransition />
      <Lottie
        animationData={mode === 'dark' ? animationBlack : animationWhite}
        loop={true}
        autoplay={true}
        style={{ width: '100px' }}
      />
    </div>
  ) : null
}

export default Loading

import React from 'react'
import { InfoContextProvider } from '../services/infoContext'
import Info from './Info'

const index = () => {
  return (
    <InfoContextProvider>
      <Info />
    </InfoContextProvider>
  )
}

export default index

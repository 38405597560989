import { ApiResponse, paramApi } from 'common/types/common'
import { Api } from '../api'
import { IColor } from 'common/types/color'

export class ColorApi extends Api {
  async getAll() {
    return await this.execute<ApiResponse<IColor[]>>(
      'get',
      `color/get-all`,
      null,
      null,
      undefined
    )
  }
}

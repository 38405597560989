import { OrderCreateContextProvider } from '../services/orderCreateContext'
import OrderCreate from './OrderCreate'

const index = () => {
  return (
    <OrderCreateContextProvider>
      <OrderCreate />
    </OrderCreateContextProvider>
  )
}

export default index

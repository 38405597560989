import { Col, Row } from 'antd'
import { IProduct } from 'common/types/products'
import { Filter } from 'components/form'
import { IField } from 'components/form/filter/Filter'
import { GetInTouch } from 'components/pages'
import { Container } from 'components/ui'
import ProductCard from 'components/ui/productCard/ProductCard'
import { useTranslation } from 'react-i18next'
import useLocalStorageState from 'use-local-storage-state'
import useWishlistContext from '../services/wishlistContext'
import '../styles/Categories.css'
import notFound from 'assets/images/notfound.svg'

const Wishlist = () => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const language = i18n.language as 'uz' | 'ru' | 'en'
  const [wishlist, setWishlist] = useLocalStorageState<IProduct[]>('wishlist', {
    defaultValue: []
  })
  const {
    state: { sizes, colors }
  } = useWishlistContext()
  return (
    <div>
      <Container size="xxl" style={{ margin: '50px auto' }}>
        <h3 className="jetbrains-mono" style={{ marginBottom: '20px' }}>
          {t('common.wishlist')}
        </h3>
        {wishlist?.length > 0 ? (
          <Row gutter={[20, 20]}>
            {wishlist?.map((product) => (
              <Col xs={24} sm={12} md={8} lg={6}>
                <ProductCard {...product} />
              </Col>
            ))}
          </Row>
        ) : (
          <div style={{ padding: '30px 0', textAlign: 'center' }}>
            <img src={notFound} width={150} />
            <p>{t('common.not_found')}</p>
          </div>
        )}
      </Container>
    </div>
  )
}

export default Wishlist

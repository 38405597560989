import api from 'common/api'
import { ICategory, ICategoryWithProducts } from 'common/types/category'
import { IColor } from 'common/types/color'
import { IHome } from 'common/types/home'
import { IProduct } from 'common/types/products'
import { ISize } from 'common/types/size'
import useQueryParams from 'hooks/useQueryParams'
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import { useParams } from 'react-router-dom'

const Context = () => {
  const { id } = useParams()
  const [colors, setColors] = useState<IColor[]>()
  const [sizes, setSizes] = useState<ISize[]>()
  const [data, setData] = useState<IProduct[]>()
  const [category, setCategory] = useState<ICategory>()
  const [loading, setLoading] = useState(true)
  const { getAllParams } = useQueryParams()

  const getCategory = async (id: string) => {
    try {
      const response = await api.category.getById(id)
      setCategory(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  const getWithProducts = async () => {
    try {
      const response = await api.product.getPaging({
        limit: 50,
        page: 1,
        category_id: id,
        ...{
          ...getAllParams,
          ...(getAllParams.sort_by && {
            sort_by: ['old', 'new'].includes(getAllParams.sort_by?.[0])
              ? 'created_at'
              : 'price',
            asc: ['price_los', 'old'].includes(getAllParams.sort_by?.[0])
              ? -1
              : 1
          })
        }
      })
      setData(response.data.data)
    } catch (err) {
      console.log(err)
    }
  }

  const getColors = async () => {
    try {
      const response = await api.color.getAll()
      setColors(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  const getSizes = async () => {
    try {
      const response = await api.size.getAll()
      setSizes(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  const requestAll = async () => {
    try {
      setLoading(true)
      await getColors()
      await getSizes()
      await getWithProducts()
      if (id) await getCategory(id)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getWithProducts()
  }, [getAllParams])

  useEffect(() => {
    requestAll()
  }, [id])
  return {
    state: { sizes, colors, data, loading, category },
    actions: {}
  }
}

const ProductsContext = createContext<any>({ state: {}, actions: {} })

export const ProductsContextProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const value = Context()
  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  )
}

export default function useProductsContext() {
  return useContext<ReturnType<typeof Context>>(ProductsContext)
}

import api from 'common/api'
import { IContact } from 'common/types/contacts'
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'

const Context = () => {
  const [contacts, setContacts] = useState<IContact[]>()

  const getSocialLinks = async () => {
    try {
      const response = await api.contacts.getAll()
      setContacts(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getSocialLinks()
  }, [])
  return {
    state: { contacts },
    actions: {}
  }
}

const FooterContext = createContext<any>({ state: {}, actions: {} })

export const FooterContextProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const value = Context()
  return (
    <FooterContext.Provider value={value}>{children}</FooterContext.Provider>
  )
}

export default function useFooterContext() {
  return useContext<ReturnType<typeof Context>>(FooterContext)
}

import { Filter } from 'components/form'
import CategoryHeader from '../components/categoryHeader/CategoryHeader'
import '../styles/Categories.css'
import { IField } from 'components/form/filter/Filter'
import useCategoriesContext from '../services/categoriesContext'
import { useTranslation } from 'react-i18next'
import { GetInTouch } from 'components/pages'
import Collection from '../components/Collection'
import ProductCard from 'components/ui/productCard/ProductCard'
import { Col, Row } from 'antd'
import { Button, Container, Loading } from 'components/ui'
import { Link } from 'react-router-dom'

const Categories = () => {
  const { t } = useTranslation()
  const {
    state: { data, loading }
  } = useCategoriesContext()
  return (
    <div>
      {loading ? (
        <Loading mode="dark" />
      ) : (
        <>
          <CategoryHeader />
          <Container size="xxl">
            {data?.data?.map((category) => (
              <div style={{ margin: '50px 0' }}>
                <h3 className="jetbrains-mono" style={{ marginBottom: '20px' }}>
                  {category.name['uz']}
                </h3>
                <Row gutter={[20, 20]}>
                  {category.products?.map((product) => (
                    <Col xs={24} sm={12} md={8} lg={6}>
                      <ProductCard {...product} />
                    </Col>
                  ))}
                </Row>
                {(category.products?.length ?? 0) > 9 && (
                  <Link to={`/products/${category._id}`}>
                    <Button
                      type="contained-gradient"
                      style={{
                        display: 'block',
                        margin: 'auto',
                        marginTop: '20px'
                      }}
                    >
                      {t('categories.see_all')}
                    </Button>
                  </Link>
                )}
              </div>
            ))}
          </Container>
          <Collection />
        </>
      )}
      <GetInTouch />
    </div>
  )
}

export default Categories

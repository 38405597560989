import { ILanguges } from './common'
import { IProduct } from './products'
import { IUser } from './user'

export interface ICreateOrder extends IUser {
  payment_method: string
  products: {
    product_id: string
    color_id: string
    size_id: string
    amount: number
  }[]
}

export enum ORDER_STATE {
  NEW = 'new',
  ACCEPTED = 'accepted',
  IN_PROGRESS = 'in_progress',
  ON_THE_WAY = 'on_the_way',
  COMPLETED = 'completed',
  CANCELED = 'canceled'
}

export interface IOrder extends IUser {
  _id: string
  number: string
  user_id: string
  total_price: number
  state: ORDER_STATE
  payment_method: 'cash' | 'payme' | 'click'
  products: {
    _id: string
    order_id: string
    product_id: string
    color_id: string
    size_id: string
    amount: number
    price: number
    created_at: string
    updated_at: string
    __v: 0
    product: IProduct
    size: {
      _id: string
      is_deleted: boolean
      code: string
      created_at: string
      updated_at: string
    }
    color: {
      _id: string
      is_deleted: boolean
      name: ILanguges<string>
      hex_code: string
      created_at: string
      updated_at: string
      image?: { source_url: string; alt: string }
    }
  }[]
  created_at: string
  updated_at: string
}

import { Form, FormProps, message } from 'antd'
import api from 'common/api'
import useUser from 'hooks/useUser'
import moment from 'moment'
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

const Context = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { user, setUser } = useUser()
  const [loading, setLoading] = useState(false)

  const onFinish: FormProps['onFinish'] = async (values) => {
    try {
      setLoading(true)
      const response = await api.user.updateProfile({
        ...user,
        ...values,
        gender: Array.isArray(values.gender) ? values.gender[0] : values.gender
      })
      setUser({
        ...response.data,
        gender:
          typeof response?.data?.gender !== 'object'
            ? [response?.data?.gender]
            : response?.data?.gender
      })
      message.success(t('profile.saved'))
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      ...user,
      birth_date: user?.birth_date && moment(user?.birth_date)
    })
  }, [])

  return {
    state: { form, loading },
    actions: { onFinish }
  }
}

const InfoContext = createContext<any>({ state: {}, actions: {} })

export const InfoContextProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const value = Context()
  return <InfoContext.Provider value={value}>{children}</InfoContext.Provider>
}

export default function useInfoContext() {
  return useContext<ReturnType<typeof Context>>(InfoContext)
}

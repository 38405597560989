import { Button, Col, DatePicker, Form, Input, Row } from 'antd'
import { IUser } from 'common/types/user'
import { MultipleChoose } from 'components/form'
import PhoneNumberInput from 'components/form/phoneNumberInput/PhoneNumberInput'
import useInfoContext from '../services/infoContext'
import { useTranslation } from 'react-i18next'

const Info = () => {
  const { t } = useTranslation()
  const {
    state: { form, loading },
    actions: { onFinish }
  } = useInfoContext()

  return (
    <div>
      <Form
        form={form}
        name="get-in-touch"
        layout="vertical"
        onFinish={onFinish}
      >
        <h1 style={{ fontFamily: "'Nunito', sans-serif" }}>
          {t('profile.personal_details')}
        </h1>
        <div style={{ marginTop: 20 }}>
          <p
            style={{
              textTransform: 'uppercase',
              color: 'rgba(11, 153, 255, 1)'
            }}
          >
            {t('profile.my_information')}
          </p>
          <Row gutter={[15, 15]} style={{ marginTop: 15 }}>
            <Col xs={24} sm={12}>
              <Form.Item<IUser> name="name" style={{ margin: 0 }}>
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item<IUser> name="surname" style={{ margin: 0 }}>
                <Input placeholder="Surname" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item<IUser> name="phone_number" style={{ margin: 0 }}>
                <PhoneNumberInput name="phone_number" form={form} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item<IUser> name="email" style={{ margin: 0 }}>
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item<IUser> name="birth_date" style={{ margin: 0 }}>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: 20 }}>
          <p
            style={{
              textTransform: 'uppercase',
              color: 'rgba(11, 153, 255, 1)'
            }}
          >
            {t('profile.gender')}
          </p>
          <Row gutter={[15, 15]} style={{ marginTop: 15 }}>
            <Col xs={24} sm={12}>
              <Form.Item<IUser> name="gender" style={{ margin: 0 }}>
                <MultipleChoose
                  multiple={false}
                  form={form}
                  name="gender"
                  options={[
                    { id: 1, label: t('profile.female'), value: 'female' },
                    { id: 2, label: t('profile.male'), value: 'male' }
                  ]}
                  onClick={(value) => form.setFieldValue('gender', value)}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: 20 }}>
          <p
            style={{
              textTransform: 'uppercase',
              color: 'rgba(11, 153, 255, 1)'
            }}
          >
            {t('profile.address_details')}
          </p>
          <Row gutter={[15, 15]} style={{ marginTop: 15 }}>
            <Col xs={24} sm={12}>
              <Form.Item<IUser> name="country" style={{ margin: 0 }}>
                <Input placeholder="Country" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item<IUser> name="region" style={{ margin: 0 }}>
                <Input placeholder="Region" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item<IUser> name="city" style={{ margin: 0 }}>
                <Input placeholder="City" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item<IUser> name="street" style={{ margin: 0 }}>
                <Input placeholder="Street" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item<IUser> name="postcode" style={{ margin: 0 }}>
                <Input placeholder="Postcode" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginTop: '15px' }}
          loading={loading}
        >
          {t('profile.save')}
        </Button>
      </Form>
    </div>
  )
}

export default Info

import { Container } from 'components/ui'
import '../styles/About.css'
import Advatanges from '../components/advantages/container/Advatanges'
import Gallery from '../components/gallery/container/Gallery'
import { GetInTouch } from 'components/pages'
import Carousel from '../components/carousel/container/Carousel'
import Guipure from '../components/guipure/container/Guipure'
import ScrollToTopWithTransition from 'utils/ScrollToTopWithTransition'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'
import AboutMain from 'assets/images/about-main.png'

const About = () => {
  const { t } = useTranslation()
  // Using useInView to trigger animations on scroll
  const [showroomRef, showroomInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const [ozodaRef, ozodaInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const [advantagesRef, advantagesInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const [galleryRef, galleryInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const [guipureRef, guipureInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const [carouselRef, carouselInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const [getInTouchRef, getInTouchInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })

  return (
    <div className="about" style={{ overflow: 'hidden' }}>
      <ScrollToTopWithTransition />

      {/* Showroom Section - Slide from Top when in view */}
      <motion.div
        ref={showroomRef}
        initial={{ opacity: 0, y: -50 }}
        animate={showroomInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        <div className="showroom">
          <Container size="xxl">
            <h1
              className="cormorant-title"
              style={{ textAlign: 'center', fontSize: '70px' }}
            >
              COUTURE
            </h1>
            <img src={AboutMain} />
          </Container>
        </div>
      </motion.div>

      {/* Ozoda Showroom Section - Slide from Top when in view */}
      <motion.div
        ref={ozodaRef}
        initial={{ opacity: 0, y: -50 }}
        animate={ozodaInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        <Container size="xxl">
          <h1 className="cormorant-title" style={{ textAlign: 'center' }}>
            OZODA COUTURE
          </h1>
          <p
            style={{
              color: 'rgba(111, 111, 111, 1)',
              lineHeight: '30px',
              margin: '30px 0px 70px 0'
            }}
          >
            {t('about.description')}
          </p>
        </Container>
      </motion.div>

      {/* Advantages Section - Slide from Left when in view */}
      <motion.div
        ref={advantagesRef}
        initial={{ opacity: 0, x: -50 }}
        animate={advantagesInView ? { opacity: 1, x: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        <Advatanges />
      </motion.div>

      {/* Gallery Section - Slide from Right when in view */}
      <motion.div
        ref={galleryRef}
        initial={{ opacity: 0, x: 50 }}
        animate={galleryInView ? { opacity: 1, x: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        <Gallery />
      </motion.div>

      {/* Guipure Section - Slide from Left when in view */}
      <motion.div
        ref={guipureRef}
        initial={{ opacity: 0, x: -50 }}
        animate={guipureInView ? { opacity: 1, x: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        <Guipure />
      </motion.div>

      {/* Carousel Section - Slide from Right when in view */}
      <motion.div
        ref={carouselRef}
        initial={{ opacity: 0, x: 50 }}
        animate={carouselInView ? { opacity: 1, x: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        <Carousel />
      </motion.div>

      {/* Get In Touch Section - Slide from Bottom when in view */}
      <motion.div
        ref={getInTouchRef}
        initial={{ opacity: 0, y: 50 }}
        animate={getInTouchInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        <GetInTouch />
      </motion.div>
    </div>
  )
}

export default About

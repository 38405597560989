import React from 'react'
import { CategoriesContextProvider } from '../services/categoriesContext'
import Categories from './Categories'

const index = () => {
  return (
    <CategoriesContextProvider>
      <Categories />
    </CategoriesContextProvider>
  )
}

export default index

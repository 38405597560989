import React from 'react'
import { WishlistContextProvider } from '../services/wishlistContext'
import Wishlist from './Wishlist'

const index = () => {
  return (
    <WishlistContextProvider>
      <Wishlist />
    </WishlistContextProvider>
  )
}

export default index

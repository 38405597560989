import { Button, Drawer, Flex, Space } from 'antd'
import React from 'react'
import useNavbarContext from '../../services/navbarContext'
import useLocalStorageState from 'use-local-storage-state'
import { IProduct } from 'common/types/products'
import { format } from 'utils/format'
import useLayoutContext from 'components/ui/layout/services/layoutContext'
import notFound from 'assets/images/notfound.svg'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons'
import useUser from 'hooks/useUser'
import useConverter from 'hooks/useConverter'
import { useTranslation } from 'react-i18next'

const BasketDrawer = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const navigate = useNavigate()
  const { converter } = useConverter()
  const [basket, setBasket] = useLocalStorageState<IProduct[]>('basket', {
    defaultValue: []
  })
  const {
    state: { isBasket },
    actions: { setIsBasket, setIsLoginModal }
  } = useNavbarContext()

  const {
    state: { currency, language }
  } = useLayoutContext()
  return (
    <Drawer open={isBasket} onClose={() => setIsBasket(false)}>
      <Flex style={{ height: '100%' }} vertical>
        <Flex justify="space-between">
          <h1 className="cormorant-sc-medium">
            {t('common.basket')} ({basket.length})
          </h1>
          <Button
            icon={<FontAwesomeIcon icon={faTrash} />}
            shape="circle"
            type="text"
            styles={{ icon: { color: 'rgba(153, 153, 153, 1)' } }}
            onClick={() => setBasket([])}
          />
        </Flex>
        <Flex
          vertical
          justify="space-between"
          style={{
            flexGrow: 1,
            position: 'relative',
            width: '100%',
            height: '100%',
            overflowY: 'auto'
          }}
        >
          {basket.length > 0 ? (
            <Space
              direction="vertical"
              styles={{ item: { width: '100%' } }}
              style={{
                marginTop: '20px',
                flexGrow: 1,
                height: '100%',
                overflowY: 'auto'
              }}
            >
              {basket.map((product) => (
                <div>
                  <Flex gap={20}>
                    <img
                      src={`${process.env.REACT_APP_FILE_URL}${product?.images.find((image) => (product.selected_color ? image.color_id === product.selected_color : image))?.source_url}`}
                      alt={product.main_image.source_url}
                      style={{
                        objectFit: 'cover',
                        width: '100px',
                        height: '130px',
                        display: 'block',
                        flexShrink: 0,
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        navigate(`product/${product._id}`)
                        setIsBasket(false)
                      }}
                    />
                    <div>
                      <p style={{ color: 'black' }}>
                        {product?.integration?.find(
                          (item) =>
                            item.size_id === product.selected_size &&
                            item.color_id === product.selected_color
                        )
                          ? format.money(
                              converter(
                                product?.integration?.find(
                                  (item) =>
                                    item.size_id === product.selected_size &&
                                    item.color_id === product.selected_color
                                )?.price,
                                product?.integration?.find(
                                  (item) =>
                                    item.size_id === product.selected_size &&
                                    item.color_id === product.selected_color
                                )?.currency_id,
                                currency
                              ),
                              currency
                            )
                          : t('products.no_price')}
                      </p>
                      <p
                        style={{
                          color: 'rgba(111, 111, 111, 1)',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          navigate(`product/${product._id}`)
                          setIsBasket(false)
                        }}
                      >
                        {product.name[language]}
                      </p>
                      <p style={{ color: 'rgba(189, 189, 189, 1)' }}>
                        {t('common.size')}:{' '}
                        {
                          product.sizes?.find(
                            (size) => size._id === product.selected_size
                          )?.code
                        }
                      </p>
                    </div>
                    <Button
                      icon={<FontAwesomeIcon icon={faXmark} />}
                      shape="circle"
                      type="text"
                      styles={{ icon: { color: 'rgba(153, 153, 153, 1)' } }}
                      onClick={() =>
                        setBasket(
                          basket.filter(
                            (item) =>
                              !(
                                item._id === product?._id &&
                                item.selected_size === product.selected_size &&
                                item.selected_color === product.selected_color
                              )
                          )
                        )
                      }
                    />
                  </Flex>
                </div>
              ))}
            </Space>
          ) : (
            <div style={{ padding: '30px 0', textAlign: 'center' }}>
              <img src={notFound} width={150} />
              <p>{t('common.not_found')}</p>
            </div>
          )}
        </Flex>
        <div style={{ paddingTop: 20 }}>
          {user ? (
            <Link to={'order/create'} onClick={() => setIsBasket(false)}>
              <Button type="primary" block>
                {t('common.checkout')}
              </Button>
            </Link>
          ) : (
            <Button
              type="primary"
              block
              onClick={() => {
                setIsLoginModal(true)
                setIsBasket(false)
              }}
            >
              {t('common.checkout')}
            </Button>
          )}
        </div>
      </Flex>
    </Drawer>
  )
}

export default BasketDrawer

import { Layout as AntLayout } from 'antd'
import { Footer, Loading, Navbar } from 'components/ui'
import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import ScrollToTopWithTransition from 'utils/ScrollToTopWithTransition'

const layoutStyle: React.CSSProperties = {
  minHeight: '100vh'
}

const Layout = () => {
  return (
    <AntLayout style={layoutStyle}>
      <Navbar />
      <AntLayout>
        <AntLayout.Content style={{ backgroundColor: 'white' }}>
          <Suspense fallback={<Loading mode="dark" />}>
            <ScrollToTopWithTransition />
            <Outlet />
          </Suspense>
        </AntLayout.Content>
      </AntLayout>
      <Footer />
    </AntLayout>
  )
}

export default Layout

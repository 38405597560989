import { faAddressCard, faClock } from '@fortawesome/free-regular-svg-icons'
import { faLocation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex, Grid, Space } from 'antd'
import { Container } from 'components/ui'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps'
import { useTransition } from 'react'
import { useTranslation } from 'react-i18next'

const { useBreakpoint } = Grid

const ContactAndLocations = () => {
  const { t } = useTranslation()
  const screens = useBreakpoint()
  return (
    <Container size="xxl" style={{ textAlign: 'center' }}>
      <div style={{ margin: '50px 0' }}>
        <h1 className="cormorant-title">{t('home.contact.title')}</h1>
      </div>
      <Flex
        vertical={screens.xs}
        gap={40}
        style={{ textAlign: 'left', margin: '90px 0' }}
        justify="space-between"
      >
        <Space direction="vertical" size="large">
          <div>
            <p>
              <FontAwesomeIcon icon={faLocation} /> Tashkent, uzbekistan
            </p>
            <p style={{ color: 'rgba(153, 153, 153, 1)' }}>
              Ozoda Showroom Kurgancha Street, Tashkent, Uzbekistan Tashkent,
              Uzbekistan
            </p>
          </div>
          <p>
            <FontAwesomeIcon icon={faAddressCard} />{' '}
            <span style={{ color: 'rgba(153, 153, 153, 1)' }}>
              {t('home.contact.phone_number')}:
            </span>{' '}
            +998 99 100 55 50
          </p>
          <p>
            <FontAwesomeIcon icon={faClock} />{' '}
            <span style={{ color: 'rgba(153, 153, 153, 1)' }}>
              {t('home.contact.working_hours')}:
            </span>{' '}
            10:00-18:00
          </p>
        </Space>
        <Space direction="vertical" size="large">
          <div>
            <p>
              <FontAwesomeIcon icon={faLocation} /> Dubai, uae
            </p>
            <p style={{ color: 'rgba(153, 153, 153, 1)' }}>
              Ozoda Showroom Kurgancha Street, Tashkent, Uzbekistan Tashkent,
              Uzbekistan
            </p>
          </div>
          <p>
            <FontAwesomeIcon icon={faAddressCard} />{' '}
            <span style={{ color: 'rgba(153, 153, 153, 1)' }}>
              {t('home.contact.phone_number')}:
            </span>{' '}
            +971 52 919 3710
          </p>
          <p>
            <FontAwesomeIcon icon={faClock} />{' '}
            <span style={{ color: 'rgba(153, 153, 153, 1)' }}>
              {t('home.contact.working_hours')}:
            </span>{' '}
            10:00-18:00
          </p>
        </Space>
      </Flex>
      <YMaps>
        <Map
          defaultState={{ center: [41.328031, 69.196432], zoom: 15 }}
          width="100%"
          height="400px"
        >
          <Placemark geometry={[41.328031, 69.196432]} />
        </Map>
      </YMaps>
    </Container>
  )
}

export default ContactAndLocations

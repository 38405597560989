import { ApiResponse, paramApi } from 'common/types/common'
import { Api } from '../api'
import { ICategory, ICategoryWithProducts } from 'common/types/category'

export class CategoryApi extends Api {
  async getAll() {
    return await this.execute<ApiResponse<ICategory[]>>(
      'get',
      `category/get-all`,
      null,
      null,
      undefined
    )
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<ICategory>>(
      'get',
      `category/get-by-id/${id}`,
      null,
      null,
      undefined
    )
  }

  async getWithProducts(payload: paramApi) {
    return await this.execute<ApiResponse<ICategoryWithProducts>>(
      'post',
      `category/with-products`,
      payload,
      null,
      undefined
    )
  }
}

import { ApiResponse } from 'common/types/common'
import { Api } from '../api'
import { IContact } from 'common/types/contacts'

export class ContactsApi extends Api {
  async getAll() {
    return await this.execute<ApiResponse<IContact[]>>(
      'get',
      `contacts/get-all`,
      null,
      null,
      undefined
    )
  }
}

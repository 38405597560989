import styled from 'styled-components'

const Button = styled.button<{
  type: 'contained' | 'outlined-gradient' | 'contained-gradient'
}>`
  padding: 15px 20px;
  border-radius: 100px;
  outline: none;
  transition: all 0.3s ease-in-out;

  ${({ type }) => {
    switch (type) {
      case 'contained':
        return `
  border: none;
  color: black;
  background-color: white;
  box-shadow: 0 0 10px 0.1px rgba(0, 0, 0, 0.1);
  `
      case 'outlined-gradient':
        return `
  background-color: transparent;
  border: 2px solid #f0017c;

  &:hover {
    background-color: #f0017c;
  }
    
  &:hover span {
    color: white;
    background: transparent;
    -webkit-text-fill-color: white;
  }
  `
      case 'contained-gradient':
        return `
  border: none;
  background: linear-gradient(268.56deg, #81013D 0%, #F0017C 100%);
  color: white;
  `
      default:
        break
    }
  }}

  font-family: 'Nunito', sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  cursor: pointer;

  span {
    background: linear-gradient(to left, #81013d 0%, #f0017c 100%);
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    transition: all 0.3s ease-in-out;
  }
`

export default Button

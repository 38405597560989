import api from 'common/api'
import { ICategoryWithProducts } from 'common/types/category'
import { IColor } from 'common/types/color'
import { IHome } from 'common/types/home'
import { ISize } from 'common/types/size'
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import { useParams } from 'react-router-dom'

const Context = () => {
  const { id } = useParams()
  const [colors, setColors] = useState<IColor[]>()
  const [sizes, setSizes] = useState<ISize[]>()
  const [data, setData] = useState<ICategoryWithProducts>()
  const [collections, setCollections] = useState<IHome>()
  const [loading, setLoading] = useState(true)

  const getCollections = async () => {
    try {
      const response = await api.home.get()
      setCollections(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  const getWithProducts = async () => {
    try {
      const response = await api.category.getWithProducts({
        limit: 10,
        page: 1,
        parent_id: id
      })
      setData(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  const getColors = async () => {
    try {
      const response = await api.color.getAll()
      setColors(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  const getSizes = async () => {
    try {
      const response = await api.size.getAll()
      setSizes(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  const requestAll = async () => {
    try {
      setLoading(true)
      await getColors()
      await getSizes()
      await getWithProducts()
      await getCollections()
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    requestAll()
  }, [id])
  return {
    state: { sizes, colors, data, collections, loading },
    actions: {}
  }
}

const CategoriesContext = createContext<any>({ state: {}, actions: {} })

export const CategoriesContextProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const value = Context()
  return (
    <CategoriesContext.Provider value={value}>
      {children}
    </CategoriesContext.Provider>
  )
}

export default function useCategoriesContext() {
  return useContext<ReturnType<typeof Context>>(CategoriesContext)
}

import api from 'common/api'
import { IGetTheLook } from 'common/types/home'
import { IProduct } from 'common/types/products'
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import { useParams } from 'react-router-dom'

const Context = () => {
  const { id } = useParams()
  const [data, setData] = useState<IGetTheLook>()
  const [loading, setLoading] = useState(true)

  const getWithProducts = async (id: string) => {
    try {
      const response = await api.home.getTheLook(id)
      setData(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  const requestAll = async () => {
    try {
      setLoading(true)
      if (id) await getWithProducts(id)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    requestAll()
  }, [id])
  return {
    state: { data, loading },
    actions: {}
  }
}

const LooksContext = createContext<any>({ state: {}, actions: {} })

export const LooksContextProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const value = Context()
  return <LooksContext.Provider value={value}>{children}</LooksContext.Provider>
}

export default function useLooksContext() {
  return useContext<ReturnType<typeof Context>>(LooksContext)
}

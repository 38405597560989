import React from 'react'
import { FooterContextProvider } from '../services/footerContext'
import Footer from './Footer'

const index = () => {
  return (
    <FooterContextProvider>
      <Footer />
    </FooterContextProvider>
  )
}

export default index

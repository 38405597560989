import { Col, Grid, Row } from 'antd'
import Guipure1 from 'assets/images/guipure1.png'
import Guipure2 from 'assets/images/guipure2.png'
import { Container } from 'components/ui'
import { useTranslation } from 'react-i18next'
import '../styles/Guipure.css'

const { useBreakpoint } = Grid

const Guipure = () => {
  const { t } = useTranslation()
  const screens = useBreakpoint()
  return (
    <div className="about-guipure" style={{ overflow: 'hidden' }}>
      <div style={{ backgroundColor: 'black', padding: '70px 0' }}>
        <Container size="xxl">
          <Row wrap justify="space-between" gutter={[50, 50]}>
            <Col xs={24} md={12}>
              <p style={{ color: 'white' }}>{t('about.guipure')}</p>
            </Col>
            <Col xs={24} md={8}>
              <img style={{ width: '100%' }} src={Guipure1} />
            </Col>
          </Row>
        </Container>
      </div>
      <Row
        style={{
          backgroundColor: 'white',
          flexDirection: !screens.md ? 'column-reverse' : 'row'
        }}
      >
        <Col xs={24} md={8}>
          <img
            style={{
              width: '100%',
              height: '800px',
              objectFit: 'cover'
            }}
            src={Guipure2}
          />
        </Col>
        <Col xs={24} md={16}>
          <div style={{ padding: '50px' }}>
            <h1 className="cormorant-title">Guipure</h1>
            <p style={{ color: 'rgba(111, 111, 111, 1)' }}>
              {t('about.guipure_desc')}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Guipure

import api from 'common/api'
import { IHome } from 'common/types/home'
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'

const Context = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<IHome>()
  const getData = async () => {
    try {
      setLoading(true)
      const response = await api.home.get()
      setData(response.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  return {
    state: { data, loading },
    actions: {}
  }
}

const HomeContext = createContext<any>({ state: {}, actions: {} })

export const HomeContextProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const value = Context()
  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>
}

export default function useHomeContext() {
  return useContext<ReturnType<typeof Context>>(HomeContext)
}

import {
  Button,
  Col,
  Collapse,
  CollapseProps,
  Divider,
  Drawer,
  Flex,
  Grid,
  Popover,
  Row,
  Space,
  Table
} from 'antd'
import { Container, Loading } from 'components/ui'
import '../styles/Product.css'
import useProductContext from '../services/productContext'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IProduct } from 'common/types/products'
import { format } from 'utils/format'
import useLayoutContext from 'components/ui/layout/services/layoutContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faHeart } from '@fortawesome/free-regular-svg-icons'
import {
  faChevronRight,
  faShoppingBasket,
  faHeart as filledHeard
} from '@fortawesome/free-solid-svg-icons'
import useLocalStorageState from 'use-local-storage-state'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import ScrollToTopWithTransition from 'utils/ScrollToTopWithTransition'
import useConverter from 'hooks/useConverter'

const { useBreakpoint } = Grid

const Product = () => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const language = i18n.language as 'uz' | 'ru' | 'en'
  const screens = useBreakpoint()
  const { converter } = useConverter()

  const [selectedSize, setSelectedSize] = useState<string | null>(null)
  const [selectedColor, setSelectedColor] = useState<string | null>(null)
  const [like, setLike] = useState(false)
  const [basket, setBasket] = useLocalStorageState<IProduct[]>('basket', {
    defaultValue: []
  })
  const [wishlist, setWishlist] = useLocalStorageState<IProduct[]>('wishlist', {
    defaultValue: []
  })
  const [open, setOpen] = useState(false)

  const {
    state: { product }
  } = useProductContext()

  const currentBasket = useMemo(
    () =>
      basket?.find(
        (item) =>
          item._id === product?._id &&
          item.selected_size === selectedSize &&
          item.selected_color === selectedColor
      ),
    [basket, product, selectedSize, selectedColor]
  )

  const productIntegration = useMemo(
    () =>
      product?.integration?.find((product) =>
        selectedSize && selectedColor
          ? product.size_id === selectedSize &&
            product.color_id === selectedColor
          : true
      ),
    [selectedColor, selectedSize]
  )

  const handleLike = () => {
    setLike((prev) => {
      if (prev) {
        setWishlist(wishlist?.filter((wish) => wish._id !== product?._id))
        return false
      } else {
        if (product) {
          setWishlist([...wishlist, product])
        }
        return true
      }
    })
  }

  const selectColor = (color: string) => {
    setSelectedColor((prev) => {
      if (prev === color) {
        return color
      } else {
        // setSelectedSize((prev) =>
        //   prev ? prev : (product?.sizes?.[0]?._id ?? null)
        // )
        // const isBasketAvailable = basket.find(
        //   (item) => item._id === product?._id
        // )
        // if (isBasketAvailable) {
        //   if (product)
        //     setBasket((prev) =>
        //       prev.map((item) =>
        //         item._id === product._id
        //           ? { ...item, selected_color: color }
        //           : item
        //       )
        //     )
        // }
        return color
      }
    })
  }

  const selectSize = (size: string) => {
    setSelectedSize((prev) => {
      if (prev === size) {
        // setSelectedColor(null)
        return size
      } else {
        // const isBasketAvailable = basket.find(
        //   (item) => item._id === product?._id
        // )
        // if (isBasketAvailable) {
        //   if (product)
        //     setBasket((prev) =>
        //       prev.map((item) =>
        //         item._id === product._id
        //           ? { ...item, selected_size: size }
        //           : item
        //       )
        //     )
        // }
        return size
      }
    })
  }

  const addToBasket = () => {
    if (currentBasket) {
      setBasket(
        basket.filter(
          (item) =>
            !(
              item._id === product?._id &&
              item.selected_size === selectedSize &&
              item.selected_color === selectedColor
            )
        )
      )
    } else {
      setOpen(true)
      if (selectedSize && selectedColor) {
        if (product)
          setBasket([
            ...basket,
            {
              ...product,
              selected_size: selectedSize,
              selected_color: selectedColor,
              count: 1
            }
          ])
      }
    }
  }

  useEffect(() => {
    const isWishlistAvailable = wishlist.find(
      (item) => item._id === product?._id
    )

    setSelectedColor(product?.colors?.[0]?._id ?? null)
    setSelectedSize(product?.sizes?.[0]?._id ?? null)

    if (isWishlistAvailable) setLike(true)
  }, [product])

  const {
    state: { currency }
  } = useLayoutContext()

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: <p>{t('products.material')}</p>,
      children: <p>{product?.description[language]}</p>
    },
    {
      key: '2',
      label: <p>{t('products.delivery')}</p>,
      children: (
        <p>
          <section
            style={{
              lineHeight: '1.6',
              color: '#333'
            }}
          >
            {/* Delivery Policy */}
            <h2 style={{ color: '#444' }}>
              {t('products.deliveryPolicy.title')}
            </h2>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li>{t('products.deliveryPolicy.processing')}</li>
              <li>{t('products.deliveryPolicy.delivery')}</li>
              <li>{t('products.deliveryPolicy.tracking')}</li>
              <li>{t('products.deliveryPolicy.shipping', { amount: '10' })}</li>
            </ul>

            {/* Return Policy */}
            <h2 style={{ color: '#444', marginTop: 10 }}>
              {t('products.returnPolicy.title')}
            </h2>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li>{t('products.returnPolicy.returns')}</li>
              <li>{t('products.returnPolicy.refunds')}</li>
              <li>{t('products.returnPolicy.exchanges')}</li>
            </ul>

            {/* Contact */}
            <p>
              {t('products.contact.email', {
                email: 'ozodashowroom@gmail.com'
              })}
            </p>
          </section>
        </p>
      )
    },
    {
      key: '3',
      label: <p>{t('products.note')}</p>,
      children: (
        <>
          <p>{t('products.note_text_1')}</p>
          <br />
          <p>{t('products.note_text_2')}</p>
          <br />
          <p>{t('products.note_text_3')}</p>
          <br />
          <p>{t('products.note_text_4')}</p>
        </>
      )
    },
    {
      key: '4',
      label: <p>{t('products.price_title')}</p>,
      children: (
        <>
          <p>{t('products.price_text')}</p>
        </>
      )
    }
  ]

  // Table data
  const sizeData = [
    {
      key: '1',
      size: 'XS',
      bust: '81-84 cm',
      waist: '63-66 cm',
      hips: '87-90 cm'
    },
    {
      key: '2',
      size: 'S',
      bust: '85-88 cm',
      waist: '67-70 cm',
      hips: '91-94 cm'
    },
    {
      key: '3',
      size: 'M',
      bust: '89-92 cm',
      waist: '71-74 cm',
      hips: '95-98 cm'
    },
    {
      key: '4',
      size: 'L',
      bust: '93-96 cm',
      waist: '75-78 cm',
      hips: '99-102 cm'
    },
    {
      key: '5',
      size: 'XL',
      bust: '97-101 cm',
      waist: '79-83 cm',
      hips: '103-106 cm'
    }
  ]

  // Table columns
  const columns = [
    {
      title: t('products.size_guide.size'),
      dataIndex: 'size',
      key: 'size'
    },
    {
      title: t('products.size_guide.bust'),
      dataIndex: 'bust',
      key: 'bust'
    },
    {
      title: t('products.size_guide.waist'),
      dataIndex: 'waist',
      key: 'waist'
    },
    {
      title: t('products.size_guide.hips'),
      dataIndex: 'hips',
      key: 'hips'
    }
  ]

  // Popover content (table)
  const popoverContent = (
    <Table
      dataSource={sizeData}
      columns={columns}
      pagination={false}
      bordered
      size="small"
    />
  )

  return product ? (
    <div className="product" style={{ margin: '10px 0' }}>
      <ScrollToTopWithTransition />
      <Container size="xxl">
        <Row align="stretch">
          <Col xs={24} md={16}>
            {screens.xs || (screens.sm && !screens.md) ? (
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                loop
                grabCursor={true}
                className="product-images-swiper"
              >
                <SwiperSlide className="product-images-swiper-slide">
                  <img
                    src={`${process.env.REACT_APP_FILE_URL}${product?.main_image.source_url}`}
                    alt={product?.main_image.alt}
                    width="100%"
                    height={500}
                  />
                </SwiperSlide>
                {product?.images.map((src, index) => (
                  <SwiperSlide
                    key={index}
                    className="product-images-swiper-slide"
                  >
                    <img
                      src={`${process.env.REACT_APP_FILE_URL}${src.source_url}`}
                      alt={src.alt}
                      width="100%"
                      height={500}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <Row gutter={[5, 5]} className="product-images-container">
                {product.images.map((image) => (
                  <Col xs={24} md={12} className="product-images-item">
                    <img
                      width="100%"
                      height={600}
                      src={`${process.env.REACT_APP_FILE_URL}${image.source_url}`}
                      alt={image.alt}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </Col>
          <Col xs={24} md={8} className="product-details">
            <Space direction="vertical">
              <p style={{ color: 'rgba(111, 111, 111, 1)' }}>
                {product.name[language]}
              </p>
              <p>
                {productIntegration
                  ? format.money(
                      converter(
                        productIntegration?.price,
                        productIntegration?.currency_id,
                        currency
                      ),
                      currency
                    )
                  : t('products.no_price')}
              </p>
              {product?.colors?.length > 0 && (
                <Flex className="color-selector" align="center" gap={10} wrap>
                  {product?.colors.map((color) => (
                    <button
                      key={color._id}
                      onClick={() => selectColor(color._id)}
                      className={selectedColor === color._id ? 'active' : ''}
                      style={{
                        background: color?.image?.source_url
                          ? `url(${color?.image?.source_url})`
                          : color.hex_code
                      }}
                    />
                  ))}
                </Flex>
              )}
            </Space>
            <Divider />
            {product?.sizes?.length > 0 && (
              <div className={`size-selector`}>
                <Flex className="size-selector-items" wrap gap={10}>
                  {product?.sizes.map((size) => (
                    <button
                      key={size._id}
                      onClick={() => selectSize(size._id)}
                      className={selectedSize === size._id ? 'active' : ''}
                    >
                      {size.code}
                    </button>
                  ))}
                </Flex>
              </div>
            )}
            <Space
              direction="vertical"
              style={{ width: '100%', marginTop: '20px' }}
              size="middle"
            >
              <Flex justify="space-between" wrap>
                <p style={{ color: 'rgba(111, 111, 111, 1)' }}>
                  {t('products.model')}:{' '}
                  {
                    product?.sizes?.find((size) => size._id === selectedSize)
                      ?.code
                  }
                </p>
                <Popover content={popoverContent} trigger="click">
                  <p style={{ cursor: 'pointer' }}>
                    {t('products.size_guide.button')}
                  </p>
                </Popover>
              </Flex>
              {/* {!!product?.integration.find(
                (product) =>
                  product.size_id === selectedSize &&
                  product.color_id === selectedColor
              )?.quantity ? (
                <p className="paragraph-success">
                  Available{' '}
                  {
                    product?.integration.find(
                      (product) =>
                        product.size_id === selectedSize &&
                        product.color_id === selectedColor
                    )?.quantity
                  }{' '}
                  clothes
                </p>
              ) : (
                <p className="paragraph-error">Not available in sale</p>
              )} */}
              <Flex gap={5}>
                <Button
                  type="primary"
                  block
                  style={{
                    height: '40px',
                    flexGrow: 1,
                    ...(currentBasket && {
                      backgroundColor: 'rgba(40, 205, 65, 1)'
                    })
                  }}
                  // disabled={
                  //   currentBasket
                  //     ? false
                  //     : !!!product?.integration.find(
                  //         (product) =>
                  //           product.size_id === selectedSize &&
                  //           product.color_id === selectedColor
                  //       )?.quantity || !selectedSize
                  // }
                  onClick={addToBasket}
                >
                  {currentBasket ? (
                    t('products.added_to_basket')
                  ) : (
                    <>
                      {t('products.add_to_basket')}{' '}
                      <FontAwesomeIcon icon={faShoppingBasket} />
                    </>
                  )}
                </Button>
                <Button
                  icon={<FontAwesomeIcon icon={like ? filledHeard : faHeart} />}
                  type="primary"
                  className="product-card-like"
                  size="large"
                  styles={{
                    icon: {
                      color: 'white'
                    }
                  }}
                  onClick={() => handleLike()}
                  style={{ height: '40px', flexShrink: 0 }}
                />
              </Flex>
              <Collapse items={items} className="product-collapse" />
            </Space>
          </Col>
        </Row>
        <Flex justify="center" align="center" gap={10}>
          <Link
            to={`/categories/${product?.category?.parent?._id}`}
            style={{ color: 'black', textDecoration: 'none' }}
          >
            <p>{product?.category?.parent?.name?.[language]}</p>
          </Link>
          <FontAwesomeIcon icon={faChevronRight} />
          <Link
            to={`/products/${product?.category?._id}`}
            style={{
              color: 'rgba(153, 153, 153, 1)',
              textDecoration: 'none',
              margin: '30px 0'
            }}
          >
            <p>{product?.category?.name?.[language]}</p>
          </Link>
        </Flex>
        <Drawer open={open} onClose={() => setOpen(false)}>
          <Flex vertical style={{ height: '100%' }}>
            <Flex gap={20}>
              <img
                src={`${process.env.REACT_APP_FILE_URL}${product?.images.find((image) => image.color_id === selectedColor)?.source_url}`}
                alt={product.main_image.source_url}
                style={{
                  objectFit: 'cover',
                  width: '100px',
                  height: '130px',
                  display: 'block',
                  flexShrink: 0
                }}
              />
              <div>
                <p>
                  {format.money(
                    converter(
                      product?.integration?.find((product) =>
                        selectedSize && selectedColor
                          ? product.size_id === selectedSize &&
                            product.color_id === selectedColor
                          : true
                      )?.price,
                      product?.integration?.find((product) =>
                        selectedSize && selectedColor
                          ? product.size_id === selectedSize &&
                            product.color_id === selectedColor
                          : true
                      )?.currency_id,
                      currency
                    ),
                    currency
                  )}
                </p>
                <p style={{ color: 'rgba(111, 111, 111, 1)' }}>
                  {product.name[language]}
                </p>
                <p style={{ color: 'rgba(189, 189, 189, 1)' }}>
                  {t('common.size')}:{' '}
                  {
                    product.sizes?.find((size) => size._id === selectedSize)
                      ?.code
                  }
                </p>
              </div>
            </Flex>
            <div
              style={{
                flexGrow: 1,
                width: '100%',
                height: '100%',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 10,
                flexDirection: 'column'
              }}
            >
              <FontAwesomeIcon
                icon={faCircleCheck}
                style={{
                  fontSize: '30px',
                  color: 'rgba(40, 205, 65, 1)'
                }}
              />
              <h1 className="cormorant-sc-medium">{t('products.added')}</h1>
            </div>
          </Flex>
        </Drawer>
      </Container>
    </div>
  ) : (
    <Loading mode="dark" />
  )
}

export default Product

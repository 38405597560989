import { Container } from 'components/ui'
import '../styles/Profile.css'
import { Col, Flex, Grid, Row } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser } from '@fortawesome/free-regular-svg-icons'
import { faBagShopping, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import useUser from 'hooks/useUser'
import { useEffect } from 'react'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'

const StyledUl = styled.li`
  flex-grow: 1;
  height: 100%;
  list-style: none;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;

  font-family: 'Nunito', sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;

  & li {
    display: block;
  }

  & li a {
    color: black;
  }

  & li:nth-child(3) {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
  }
`

const { useBreakpoint } = Grid

const Profile = () => {
  const { t } = useTranslation()
  const { removeAuth } = useAuth()
  const { user, removeUser } = useUser()
  const location = useLocation()
  const screens = useBreakpoint()
  const navigate = useNavigate()

  const logout = () => {
    removeAuth()
    removeUser()
    navigate('/')
  }

  return (
    <Container size="xxl" style={{ margin: '20px auto', minHeight: '500px' }}>
      <Row style={{ height: '100%' }}>
        <Col
          xs={
            location.pathname !== '/profile' &&
            (screens.xs || (screens.sm && !screens.md))
              ? 0
              : 6
          }
          style={{ height: '100%' }}
        >
          <Flex style={{ height: '100%' }} vertical>
            <div>
              <h2
                style={{
                  marginBottom: 10,
                  fontFamily: "'Nunito', sans-serif"
                }}
              >
                {t('profile.hi')}, {user?.name || 'user'}
              </h2>
              <p style={{ color: 'rgba(111, 111, 111, 1)' }}>
                {user?.phone_number}
              </p>
            </div>
            <StyledUl>
              <li>
                <Link to={'/profile/info'}>
                  <FontAwesomeIcon icon={faCircleUser} />{' '}
                  {t('profile.personal_details')}
                </Link>
              </li>
              <li>
                <Link to={'/profile/purchases'}>
                  <FontAwesomeIcon icon={faBagShopping} />{' '}
                  {t('profile.my_orders.title')}
                </Link>
              </li>
              <li style={{ cursor: 'pointer' }} onClick={logout}>
                <p>
                  <FontAwesomeIcon icon={faPowerOff} /> {t('profile.logout')}
                </p>
              </li>
            </StyledUl>
          </Flex>
        </Col>
        <Col
          xs={
            location.pathname !== '/profile' &&
            (screens.xs || (screens.sm && !screens.md))
              ? 24
              : 0
          }
          sm={18}
        >
          <Outlet />
        </Col>
      </Row>
    </Container>
  )
}

export default Profile

import { faSliders } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Divider,
  Drawer,
  Flex,
  Form,
  FormProps,
  Grid,
  Space
} from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useQueryParams from '../../../hooks/useQueryParams'
import MultipleSelect from '../multipleSelect/MultipleSelect'
import useFilterContext from './context/useFilter'
import MultipleChoose from '../multipleChoose/MultipleChoose'
import SearchFilter from '../searchFilter/SearchFilter'
import { Container } from 'components/ui'
import { BorderOutlined, AppstoreFilled } from '@ant-design/icons'
import useLayoutContext from 'components/ui/layout/services/layoutContext'

const { useBreakpoint } = Grid

export interface IField {
  type: 'multiplechoose' | 'multipleselect' | 'search' | 'choose'
  name: string
  options?: {
    id?: number
    label: string
    value: string | any
  }[]
  label?: string
  placeholder?: string
}

export interface IFilter<FieldType> {
  initialValues?: FieldType[]
  onFinish: FormProps<FieldType>['onFinish']
  onFinishFailed?: FormProps<FieldType>['onFinishFailed']
  filterFields: IField[]
  onViewChange?: (type: 'list' | 'card') => void
}

const Filter = <FieldType,>({
  initialValues,
  onFinish,
  onFinishFailed,
  filterFields,
  onViewChange
}: IFilter<FieldType>) => {
  const [active, setActive] = useState<'list' | 'card'>('card')
  const [open, setOpen] = useState(false)

  const { t } = useTranslation()
  const { getAllParams } = useQueryParams()
  const [form] = Form.useForm()
  const screens = useBreakpoint()

  const {
    actions: { handleSearch, handleChoose, handleMultipleSelect }
  } = useFilterContext()

  const renderField = (field: IField) => {
    switch (field.type) {
      case 'search':
        return (
          <SearchFilter
            label={field.label}
            name={field.name}
            form={form}
            onChange={(value) => handleSearch(field.name, value)}
          />
        )
      case 'multiplechoose':
        return (
          <MultipleChoose
            label={field.label}
            options={field.options!}
            name={field.name}
            form={form}
            onClick={(value) => {
              form.setFieldValue(field.name, value)
              handleChoose(field.name, value)
            }}
          />
        )
      case 'choose':
        return (
          <MultipleChoose
            label={field.label}
            options={field.options!}
            name={field.name}
            form={form}
            onClick={(value) => {
              form.setFieldValue(field.name, value)
              handleChoose(field.name, value)
            }}
            multiple={false}
          />
        )
      case 'multipleselect':
        return (
          <MultipleSelect
            label={field.label}
            placeholder={field.placeholder}
            options={field.options!}
            name={field.name}
            form={form}
            onSelect={(value) => handleMultipleSelect(field.name, value)}
          />
        )
      default:
        return ''
    }
  }

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const paramFields = getAllParams
    form.setFieldsValue(paramFields)
  }, [getAllParams])

  useEffect(() => {
    if (onViewChange) onViewChange(active)
  }, [active])

  const {
    state: { navRef }
  } = useLayoutContext()

  return (
    <div
      className="category-filter"
      style={{
        position: 'sticky',
        top: navRef.current?.clientHeight,
        zIndex: 3
      }}
    >
      <Container size="xxl">
        <Flex justify="space-between" align="center">
          <Space className="view-type">
            <Button
              icon={<BorderOutlined />}
              shape="circle"
              size="large"
              type="text"
              styles={{ icon: { opacity: active === 'list' ? 1 : 0.3 } }}
              onClick={() => setActive('list')}
            />
            <Button
              icon={<AppstoreFilled />}
              shape="circle"
              size="large"
              type="text"
              styles={{ icon: { opacity: active === 'card' ? 1 : 0.3 } }}
              onClick={() => setActive('card')}
            />
          </Space>
          <Button
            type="text"
            shape="round"
            icon={<FontAwesomeIcon icon={faSliders} />}
            onClick={showDrawer}
          >
            {t('filter.title')}
          </Button>
          <Drawer
            title="Filter"
            onClose={onClose}
            open={open}
            styles={{ header: { fontFamily: "'Nunito', sans-serif" } }}
          >
            <Form
              form={form}
              name="filter"
              layout="vertical"
              initialValues={initialValues}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Space direction="vertical" style={{ width: '100%' }}>
                {filterFields?.map((field) => {
                  return (
                    <>
                      {renderField(field)}
                      <Divider />
                    </>
                  )
                })}
              </Space>
            </Form>
          </Drawer>
        </Flex>
      </Container>
    </div>
  )
}

export default Filter

import { round, ceil, floor } from 'mathjs'

export class UMath {
  public precision = 0
  public round_type: 'round' | 'ceil' | 'floor' = 'floor'

  constructor(precision?: number, round_type?: 'round' | 'ceil' | 'floor') {
    if (precision && precision >= 0) this.precision = precision
    if (round_type) this.round_type = round_type
  }

  private strip(value: number): number {
    return parseFloat(value.toFixed(this.precision))
  }

  public add(a: number, b: number) {
    return this.round(this.round(a) + this.round(b))
  }

  public subtract(a: number, b: number) {
    return this.round(this.round(a) - this.round(b))
  }

  public multiply(a: number, b: number) {
    return this.strip(this.strip(a) * this.strip(b))
  }

  public divide(a: number, b: number) {
    return this.strip(this.strip(a) / this.strip(b))
  }

  ceil(value: number) {
    value = round(value, this.precision + 2)
    const numerator = ceil(value * Math.pow(10, this.precision))
    const denominator = Math.pow(10, this.precision)
    return round(numerator / denominator, this.precision)
  }

  floor(value: number) {
    value = round(value, this.precision + 2)
    const numerator = floor(value * Math.pow(10, this.precision))
    const denominator = Math.pow(10, this.precision)
    return round(numerator / denominator, this.precision)
  }

  round(value: number) {
    value = round(value, this.precision + 2)
    return round(value, this.precision)
  }

  specialRound(value: number) {
    switch (this.round_type) {
      case 'round':
        return this.round(value)
      case 'ceil':
        return this.ceil(value)
      case 'floor':
        return this.floor(value)
      default:
        break
    }
  }
}

export const uMath = new UMath()

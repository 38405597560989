import React, { FC, createContext, useContext } from "react";
import useQueryParams from "../../../../hooks/useQueryParams";

const Context = () => {
  const { setParam } = useQueryParams();

  const handleSearch = (name: string, value: string) => {
    setParam({ name, value: value.length ? value : undefined });
  };

  const handleChoose = (name: string, value: string[]) => {
    setParam({ name, value });
  };

  const handleMultipleSelect = (name: string, value: string[]) => {
    setParam({ name, value: value.length ? value : undefined });
  };

  return {
    state: {},
    actions: { handleSearch, handleChoose, handleMultipleSelect },
  };
};

const FilterContext = createContext<any>({ state: {}, actions: {} });

export const FilterContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

export default function useFilterContext() {
  return useContext<ReturnType<typeof Context>>(FilterContext);
}

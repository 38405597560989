import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, Flex, Grid } from 'antd'
import Container from 'components/ui/container/Container'
import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/Footer.css'
import useFooterContext from '../services/footerContext'
import { useTranslation } from 'react-i18next'

const { useBreakpoint } = Grid

const Footer = () => {
  const { t } = useTranslation()
  const {
    state: { contacts }
  } = useFooterContext()
  const footerItems: {
    icon?: React.ReactNode
    title: string
    items: {
      type: 'title' | 'link'
      label: string
      link: string
      target: 'external' | 'internal'
      description?: string
    }[]
  }[] = [
    {
      icon: <FontAwesomeIcon icon={faEnvelope} />,
      title: t('footer.contact.title'),
      items: [
        {
          type: 'title',
          label: t('footer.contact.write_telegram'),
          link: 'https://t.me/ozoda_showroom',
          target: 'external',
          description: t('footer.contact.reply')
        }
      ]
    },
    {
      title: t('footer.help.title'),
      items: [
        {
          type: 'link',
          label: t('footer.help.about_us'),
          link: '/about',
          target: 'internal'
        },
        {
          type: 'link',
          label: t('footer.help.cooperation'),
          link: '/cooperation',
          target: 'internal'
        }
      ]
    },
    {
      title: t('footer.brand_name.title'),
      items: [
        {
          type: 'link',
          label: t('footer.brand_name.about'),
          link: '#',
          target: 'internal'
        },
        {
          type: 'link',
          label: t('footer.brand_name.gallery'),
          link: '#',
          target: 'internal'
        }
      ]
    },
    {
      title: t('footer.social.title'),
      items:
        contacts?.map((contact) => ({
          type: 'link',
          label: contact.name,
          link: contact.link,
          target: 'external'
        })) ?? []
    }
  ]

  const screens = useBreakpoint()
  return (
    <div className="footer">
      <Container size="xxl">
        <Flex
          className="links"
          justify={screens.xs ? 'center' : 'space-between'}
          gap={screens.xs ? 40 : 20}
          vertical={screens.xs}
        >
          {footerItems.map((item) => (
            <div className="links-item">
              <h3
                className="jetbrains-mono"
                style={{
                  marginBottom: 10,
                  textAlign: screens.xs ? 'center' : 'left'
                }}
              >
                {item.icon} {item.title}
              </h3>
              <ol style={{ listStyle: 'none' }}>
                {item.items.map((link) =>
                  link.type === 'title' ? (
                    <li style={{ textAlign: screens.xs ? 'center' : 'left' }}>
                      <h3
                        className="jetbrains-mono"
                        style={{ textAlign: screens.xs ? 'center' : 'left' }}
                      >
                        {link.target === 'external' ? (
                          <a href={link.link}>{link.label}</a>
                        ) : (
                          <Link to={link.link}>{link.label}</Link>
                        )}
                      </h3>
                      <span
                        className="jetbrains-mono"
                        style={{
                          color: 'rgba(153, 153, 153, 1)',
                          textAlign: screens.xs ? 'center' : 'left'
                        }}
                      >
                        {link.description}
                      </span>
                    </li>
                  ) : (
                    <li style={{ textAlign: screens.xs ? 'center' : 'left' }}>
                      {link.target === 'external' ? (
                        <a className="jetbrains-mono" href={link.link}>
                          {link.label}
                        </a>
                      ) : (
                        <Link className="jetbrains-mono" to={link.link}>
                          {link.label}
                        </Link>
                      )}
                    </li>
                  )
                )}
              </ol>
            </div>
          ))}
        </Flex>
        <Divider />
        <Flex style={{ paddingBottom: '25px' }} justify="space-between">
          <p style={{ color: 'rgba(153, 153, 153, 1)' }}>
            © OZODA SHOWROOM 2024
          </p>
          <p style={{ color: 'rgba(153, 153, 153, 1)' }}>
            developed by <a href="https://www.unical.uz">UNICAL SOLUTIONS</a>
          </p>
        </Flex>
      </Container>
    </div>
  )
}

export default Footer

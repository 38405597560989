import React from 'react'
import Purchases from './Purchases'
import { PurchasesContextProvider } from '../services/purchasesContext'

const index = () => {
  return (
    <PurchasesContextProvider>
      <Purchases />
    </PurchasesContextProvider>
  )
}

export default index

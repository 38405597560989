import { ApiResponse } from 'common/types/common'
import { Api } from '../api'
import { IHome } from 'common/types/home'

export class HomeApi extends Api {
  async get() {
    return await this.execute<ApiResponse<IHome>>(
      'get',
      `main-page/get`,
      null,
      null,
      undefined
    )
  }

  async getTheLook(id: string) {
    return await this.execute<ApiResponse<any>>(
      'get',
      `main-page/get-the-look/${id}`,
      null,
      null,
      undefined
    )
  }

  async request(payload: {
    name: string
    email: string
    phone_number: string
    text?: string
  }) {
    return await this.execute<ApiResponse<any>>(
      'post',
      `requests/send`,
      payload,
      null,
      undefined
    )
  }

  async subscribe(payload: { email: string }) {
    return await this.execute<ApiResponse<any>>(
      'post',
      `subscription/subscribe`,
      payload,
      null,
      undefined
    )
  }
}

import {
  faBagShopping,
  faBars,
  faCheck,
  faClose,
  faEarthAsia,
  faHeart,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Col,
  Drawer,
  Flex,
  Form,
  FormProps,
  Grid,
  Input,
  Layout,
  Modal,
  Popover,
  Row,
  Space
} from 'antd'
import api from 'common/api'
import PhoneNumberInput from 'components/form/phoneNumberInput/PhoneNumberInput'
import { Container } from 'components/ui'
import useLayoutContext from 'components/ui/layout/services/layoutContext'
import useAuth from 'hooks/useAuth'
import useUser from 'hooks/useUser'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import BasketDrawer from '../components/basketDrawer/BasketDrawer'
import Search from '../components/search/container'
import useNavbarContext from '../services/navbarContext'
import '../styles/Navbar.css'
import { StyledCategoriesModal } from '../styles/Navbar.styles'
import logoBlack from 'assets/images/logo_black.svg'
import logoWhite from 'assets/images/logo.svg'

const { Header } = Layout

const { useBreakpoint } = Grid

const languages: { symbol: 'uz' | 'ru' | 'en'; label: string }[] = [
  {
    symbol: 'uz',
    label: "O'zbek"
  },
  {
    symbol: 'ru',
    label: 'Русский'
  },
  {
    symbol: 'en',
    label: 'English'
  }
]

const ContentPopover = () => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState<'language' | 'currency'>('currency')

  const {
    state: { language, currency, currencies },
    actions: { handleCurrency, handleLanguage }
  } = useLayoutContext()

  return (
    <div className="language-currency">
      <Row className="language-currency-header">
        <Col xs={12}>
          <p
            className={`${selected === 'currency' && 'active'}`}
            onClick={() => setSelected('currency')}
          >
            {t('navbar.currency')}
          </p>
        </Col>
        <Col xs={12}>
          <p
            className={`${selected === 'language' && 'active'}`}
            onClick={() => setSelected('language')}
          >
            {t('navbar.language')}
          </p>
        </Col>
      </Row>
      {selected === 'currency' ? (
        <div className="language-currency-content language-currency-content-currency">
          {currencies.map((item) => (
            <div
              className={`language-currency-content-language-item ${item._id === currency && 'active'}`}
              onClick={() => handleCurrency(item._id)}
            >
              <span className="symbol" style={{ textTransform: 'uppercase' }}>
                {item.symbol}
              </span>
              <span className="label">{item.name}</span>
              <FontAwesomeIcon
                className="icon"
                icon={faCheck}
                style={{
                  transition: 'all 0.3s ease-in-out',
                  color: 'rgba(11, 153, 255, 1)',
                  opacity: item._id === currency ? 1 : 0
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="language-currency-content language-currency-content-language">
          {languages.map((item) => (
            <div
              className={`language-currency-content-language-item ${item.symbol === language && 'active'}`}
              onClick={() => handleLanguage(item.symbol)}
            >
              <span className="symbol" style={{ textTransform: 'uppercase' }}>
                {item.symbol}
              </span>
              <span className="label">{item.label}</span>
              <FontAwesomeIcon
                className="icon"
                icon={faCheck}
                style={{
                  transition: 'all 0.3s ease-in-out',
                  color: 'rgba(11, 153, 255, 1)',
                  opacity: item.symbol === language ? 1 : 0
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const ContentMenu = () => {
  const { t } = useTranslation()
  return (
    <Flex className="menu-links-item-content" vertical>
      <Link className="jetbrains-mono" to="/about">
        {t('navbar.links.about_us')}
      </Link>
      <Link className="jetbrains-mono" to="/cooperation">
        {t('navbar.links.cooperation')}
      </Link>
    </Flex>
  )
}

const CategoriesModal = ({
  open,
  setOpen
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const {
    state: { data, loading }
  } = useNavbarContext()

  const screens = useBreakpoint()

  const [active, setActive] = useState<string>()
  const { i18n } = useTranslation()
  const language = i18n.language as 'uz' | 'ru' | 'en'

  useEffect(() => {
    if (data) setActive(data?.[0]?._id)
  }, [data])

  const {
    state: { navRef }
  } = useLayoutContext()
  return (
    <StyledCategoriesModal
      open={open}
      onCancel={() => setOpen(false)}
      onOk={() => setOpen(false)}
      maskStyle={{
        top: navRef.current?.clientHeight
      }}
      style={{
        top: (navRef.current?.clientHeight ?? 0) + 20
      }}
      // bodyStyle={{ boxShadow: 'none' }}
      footer={false}
      closeIcon={false}
    >
      <ol className="side-navigation">
        {data?.map((category) => (
          <li
            className={category._id === active ? 'active' : ''}
            onClick={() => setActive(category._id)}
          >
            <p>{category.name[language]}</p>
          </li>
        ))}
      </ol>
      <div className="side-navigation-content">
        <p style={{ color: 'rgba(189, 189, 189, 1)', marginBottom: 10 }}>
          {t('navbar.links.categories')}
        </p>
        <Row
          style={{
            height: '100%',
            overflowY: 'auto',
            paddingBottom: '50px',
            alignContent: 'flex-start'
          }}
          gutter={[10, 10]}
        >
          {data
            ?.find((category) => category._id === active)
            ?.children?.map((child) => (
              <Col xs={12} md={8} onClick={() => setOpen(false)}>
                <Link to={`categories/${child._id}`}>
                  <p>{child.name[language]}</p>
                </Link>
              </Col>
            ))}
        </Row>
      </div>
    </StyledCategoriesModal>
  )
}

const LoginModal = ({
  open,
  setOpen
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const { setAuth } = useAuth()
  const { setUser } = useUser()
  const [form] = Form.useForm()
  const [otp, setOtp] = useState<{
    sent: boolean
    loading: boolean
    _id?: string
  }>()
  const [loading, setLoading] = useState(false)

  const getOTP = async () => {
    try {
      setOtp({ sent: false, loading: true })
      const response = await api.user.otpSend(form.getFieldsValue())
      setOtp({ sent: true, loading: false, _id: response.data })
    } catch (err) {
      setOtp({ sent: false, loading: false })
      console.log(err)
    }
  }

  const onFinish: FormProps['onFinish'] = async (values) => {
    try {
      setLoading(true)
      const response = await api.user.otpCheck({
        _id: otp?._id ?? '',
        code: parseFloat(values.otp)
      })
      setLoading(false)
      setAuth(response.data.token)
      setUser(response.data.user)
      setOtp({ sent: false, loading: false })
      form.resetFields()
      setOpen(false)
    } catch (err) {
      // setOtp({ sent: false, loading: false })
      setLoading(false)
      console.log(err)
    }
  }

  return (
    <Modal
      open={open}
      onCancel={() => {
        setOpen(false)
        setOtp({ sent: false, loading: false })
        form.resetFields()
      }}
      footer={false}
      centered
    >
      <Form form={form} onFinish={onFinish}>
        {!otp?.sent && (
          <Flex justify="center" align="center" gap={20} vertical>
            <p style={{ color: 'rgba(111, 111, 111, 1)' }}>
              {t('navbar.login.phone_number')}
            </p>
            <Form.Item name="phone_number" rules={[{ required: true }]} noStyle>
              <PhoneNumberInput name="phone_number" form={form} />
            </Form.Item>
            <Button
              type="primary"
              block
              onClick={getOTP}
              loading={otp?.loading}
            >
              {t('navbar.login.get_the_code')}
            </Button>
            <p
              style={{
                lineHeight: 'auto',
                color: 'rgba(111, 111, 111, 1)',
                textAlign: 'center'
              }}
            >
              {t('navbar.login.get_the_code_desc')}
            </p>
          </Flex>
        )}
        {otp?.sent && (
          <Flex justify="center" align="center" gap={20} vertical>
            <p style={{ color: 'rgba(111, 111, 111, 1)' }}>
              {t('navbar.login.enter_code')}
            </p>
            <Form.Item name="otp" rules={[{ required: true }]}>
              <Input.OTP size="large" length={5} />
            </Form.Item>
            <Button type="primary" block htmlType="submit" loading={loading}>
              {t('navbar.login.login')}
            </Button>
          </Flex>
        )}
      </Form>
    </Modal>
  )
}

const Navbar = () => {
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { user } = useUser()
  const [isCategoriesModal, setIsCategoriesModal] = useState(false)
  const [isDrawer, setIsDrawer] = useState(false)
  const navigate = useNavigate()
  const {
    state: { language, currency, navRef, currencies }
  } = useLayoutContext()
  const {
    state: { isLoginModal },
    actions: { setIsBasket, setIsLoginModal }
  } = useNavbarContext()

  const currentLanguage = languages.find((item) => item.symbol === language)
  const currentCurrency = currencies?.find((item) => item._id === currency)
  const screens = useBreakpoint()

  return (
    <Header
      ref={navRef}
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 5,
        width: '100%',
        backgroundColor: 'transparent',
        margin: 0,
        padding: 0,
        height: 'auto',
        lineHeight: 'normal'
      }}
      className="navbar"
    >
      <div
        style={{
          background: 'linear-gradient(0deg, #041E3A 0%, #041E3A 100%)',
          padding: '13px 0',
          color: 'white'
        }}
      >
        <Container size="xxl">
          <span
            className="jetbrains-mono"
            style={{ textAlign: 'center', flexGrow: 1 }}
          >
            {t('navbar.description')}
          </span>
          <Popover
            placement="bottomRight"
            content={ContentPopover}
            arrow={false}
            overlayInnerStyle={{ margin: 0, padding: 0, borderRadius: '15px' }}
          >
            <span
              className="jetbrains-mono"
              style={{ float: 'right', cursor: 'pointer' }}
            >
              <FontAwesomeIcon icon={faEarthAsia} style={{ marginRight: 8 }} />
              <span>
                <span style={{ textTransform: 'uppercase' }}>
                  {currentLanguage?.symbol}
                </span>{' '}
                {currentLanguage?.label}
              </span>
              ,{' '}
              <span style={{ textTransform: 'uppercase' }}>
                {currentCurrency?.symbol ?? currentCurrency?.name}
              </span>
            </span>
          </Popover>
        </Container>
      </div>

      <div className="menu">
        <Container size="xxl">
          {screens.md && (
            <Flex justify="space-between" align="center">
              <Flex className="menu-links" gap={20}>
                <Popover
                  placement="bottomLeft"
                  content={ContentMenu}
                  arrow={false}
                  overlayInnerStyle={{
                    margin: 0,
                    padding: 0,
                    borderRadius: '15px'
                  }}
                >
                  <p className="menu-links-item">
                    <FontAwesomeIcon icon={faBars} /> {t('navbar.links.menu')}
                  </p>
                </Popover>
                <p
                  className="menu-links-item"
                  onClick={() => setIsCategoriesModal((prev) => !prev)}
                >
                  {t('navbar.links.categories')}
                </p>
              </Flex>
              <Link to="/" style={{ color: 'black' }}>
                <div className="menu-item logo">
                  <Space>
                    <img src={logoBlack} alt="ozoda-showroom-logo" width={60} />
                  </Space>
                </div>
              </Link>
              <Flex className="menu-links" gap={15}>
                <Search />
                <Button
                  icon={<FontAwesomeIcon icon={faUser} />}
                  shape="circle"
                  type="text"
                  onClick={() => {
                    if (auth) {
                      navigate('/profile/info')
                    } else {
                      setIsLoginModal(true)
                    }
                  }}
                />
                <Button
                  icon={<FontAwesomeIcon icon={faHeart} />}
                  shape="circle"
                  type="text"
                  onClick={() => navigate('/wishlist')}
                />
                <Button
                  icon={<FontAwesomeIcon icon={faBagShopping} />}
                  shape="circle"
                  type="text"
                  onClick={() => setIsBasket(true)}
                />
              </Flex>
            </Flex>
          )}

          {((screens.sm === true && screens.md === false) || screens.xs) && (
            <Flex justify="space-between" align="center">
              <Link to={'/'} style={{ color: 'black' }}>
                <div className="menu-item logo">
                  <img src={logoBlack} alt="ozoda-showroom-logo" width={60} />
                </div>
              </Link>
              <Flex className="menu-links" gap={5}>
                <Search />
                <Button
                  icon={<FontAwesomeIcon icon={faBagShopping} />}
                  shape="circle"
                  type="text"
                  size="large"
                  onClick={() => setIsBasket(true)}
                />
                <Button
                  icon={<FontAwesomeIcon icon={faBars} />}
                  shape="circle"
                  type="text"
                  size="large"
                  onClick={() => setIsDrawer(true)}
                />
              </Flex>
              <Drawer
                open={isDrawer}
                onClose={() => setIsDrawer(false)}
                closeIcon={false}
                bodyStyle={{ backgroundColor: 'black', color: 'white' }}
              >
                <Flex justify="space-between" align="flex-start">
                  <img src={logoWhite} alt="ozoda-showroom-logo" width={60} />
                  <Button
                    onClick={() => setIsDrawer(false)}
                    icon={<FontAwesomeIcon icon={faClose} />}
                    type="text"
                    style={{
                      color: 'white',
                      backgroundColor: 'rgba(255,255,255,0.1)'
                    }}
                    shape="circle"
                    size="large"
                  />
                </Flex>
                <hr
                  style={{
                    borderColor: 'rgba(255, 255, 255, 0.3)',
                    borderWidth: 0.5,
                    margin: '15px 0'
                  }}
                />
                <ol
                  style={{ listStyle: 'none' }}
                  className="mobile-navbar-menu"
                >
                  <li onClick={() => setIsDrawer(false)}>
                    <Link to="/about" className="jetbrains-mono">
                      {t('navbar.links.about_us')}
                    </Link>
                  </li>
                  <li onClick={() => setIsDrawer(false)}>
                    <Link to="/cooperation" className="jetbrains-mono">
                      {t('navbar.links.cooperation')}
                    </Link>
                  </li>
                  <hr
                    style={{
                      borderColor: 'rgba(255, 255, 255, 0.3)',
                      borderWidth: 0.5,
                      margin: '15px 0'
                    }}
                  />
                  {auth ? (
                    <li onClick={() => setIsDrawer(false)}>
                      <Link to="/profile" className="jetbrains-mono">
                        <FontAwesomeIcon icon={faUser} />{' '}
                        {t('navbar.links.profile')}
                      </Link>
                    </li>
                  ) : (
                    <li onClick={() => setIsLoginModal(true)}>
                      <p className="jetbrains-mono">
                        <FontAwesomeIcon icon={faUser} />{' '}
                        {t('navbar.links.profile')}
                      </p>
                    </li>
                  )}
                  <li onClick={() => setIsDrawer(false)}>
                    <Link to="/wishlist" className="jetbrains-mono">
                      <FontAwesomeIcon icon={faHeart} />{' '}
                      {t('navbar.links.wishlist')}
                    </Link>
                  </li>
                </ol>
              </Drawer>
            </Flex>
          )}
        </Container>
      </div>
      <BasketDrawer />
      <CategoriesModal
        open={isCategoriesModal}
        setOpen={setIsCategoriesModal}
      />
      <LoginModal open={isLoginModal} setOpen={setIsLoginModal} />
    </Header>
  )
}

export default Navbar

import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button as AntButton, Col, Flex, Grid, Row } from 'antd'
import { Button, Container } from 'components/ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import useHomeContext from '../services/homeContext'
import { t } from 'i18next'
import { Link } from 'react-router-dom'

const { useBreakpoint } = Grid

const GetTheLook = () => {
  const { t } = useTranslation()
  const [swiperRef, setSwiperRef] = useState<SwiperCore | null>(null)
  const [active, setActive] = useState(0)
  const { i18n } = useTranslation()
  const language = i18n.language as 'uz' | 'ru' | 'en'

  const screens = useBreakpoint()

  const next = () => {
    swiperRef?.slideNext()
  }

  const prev = () => {
    swiperRef?.slidePrev()
  }

  const handleSlideNext = (swiper: SwiperCore) => {
    setActive(swiper.activeIndex)
  }

  const {
    state: { data }
  } = useHomeContext()

  return data ? (
    <div className="get-the-look">
      <Container size="xxl" style={{ overflow: 'visible' }}>
        <Row justify="space-between" gutter={[20, 50]}>
          <Col sm={24} md={6}>
            <Flex
              className="get-the-look-text"
              vertical
              justify="space-between"
              style={{ height: '100%' }}
            >
              <div>
                <h1
                  className="cormorant-title"
                  style={{
                    marginBottom: 10,
                    textTransform: 'uppercase'
                  }}
                >
                  {t('home.get_the_look.title')}
                </h1>
                <p
                  className="jetbrains-mono"
                  style={{ color: 'rgba(189, 189, 189, 1)', marginBottom: 40 }}
                >
                  #OZODASTYLE
                </p>
                {/* <Button type="outlined-gradient">
                  <span>{t('home.get_the_look.button')}</span>
                </Button> */}
              </div>

              {!screens.xs && (
                <Flex
                  className="get-the-look-swiper-actions"
                  justify="flex-end"
                  gap={10}
                >
                  <AntButton
                    icon={<FontAwesomeIcon icon={faChevronLeft} />}
                    onClick={() => prev()}
                    shape="circle"
                    size="large"
                  />
                  <AntButton
                    icon={<FontAwesomeIcon icon={faChevronRight} />}
                    onClick={() => next()}
                    shape="circle"
                    size="large"
                  />
                </Flex>
              )}
            </Flex>
          </Col>
          <Col sm={24} md={18}>
            <div style={{ width: '100%' }}>
              <Swiper
                onSwiper={setSwiperRef}
                onSlideChange={handleSlideNext}
                slidesPerView={2.5}
                spaceBetween={30}
                pagination={{
                  clickable: true
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1.3,
                    spaceBetween: 20
                  },
                  790: {
                    slidesPerView: 2.5,
                    spaceBetween: 30
                  }
                }}
                // centeredSlides={true}
                grabCursor={true}
                // modules={[Pagination]}
                className="get-the-look-swiper"
              >
                {data?.get_the_look.map((item) => (
                  <SwiperSlide className="get-the-look-swiper-item">
                    <Link to={`/looks/${item._id}`}>
                      <img
                        src={`${process.env.REACT_APP_FILE_URL}${item.image.source_url}`}
                        alt={item.image.alt}
                      />
                      <div className="overlay">
                        <h1 className="cormorant-sc-medium">
                          {item.name[language]}
                        </h1>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="get-the-look-swiper-placeholder">
                <div
                  className="get-the-look-swiper-placeholder-active"
                  style={{
                    width: `${((active + 2) / (swiperRef?.slides?.length ?? 1)) * 100}%`,
                    maxWidth: '100%'
                  }}
                ></div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  ) : null
}

export default GetTheLook

import { Col, Row } from 'antd'
import { Filter } from 'components/form'
import { IField } from 'components/form/filter/Filter'
import { GetInTouch } from 'components/pages'
import { Container, Loading } from 'components/ui'
import ProductCard from 'components/ui/productCard/ProductCard'
import { useTranslation } from 'react-i18next'
import useProductsContext from '../services/productsContext'
import '../styles/Products.css'
import CategoryHeader from '../components/CategoryHeader'

const Products = () => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const language = i18n.language as 'uz' | 'ru' | 'en'
  const {
    state: { sizes, colors, data, loading }
  } = useProductsContext()
  const filterFields: IField[] = [
    {
      type: 'choose',
      name: 'sort_by',
      label: t('filter.sort_by.title'),
      options: [
        {
          label: t('filter.sort_by.new'),
          value: 'new'
        },
        {
          label: t('filter.sort_by.old'),
          value: 'old'
        },
        {
          label: t('filter.sort_by.price_los'),
          value: 'price_los'
        },
        {
          label: t('filter.sort_by.price_high'),
          value: 'price_high'
        }
      ]
    },
    {
      type: 'multiplechoose',
      name: 'size_ids',
      label: t('common.size'),
      options: sizes?.map((size) => ({ label: size.code, value: size._id }))
    },
    {
      type: 'multiplechoose',
      name: 'color_ids',
      label: t('common.color'),
      options: colors?.map((color) => ({
        label: color.name[language],
        value: color._id
      }))
    }
  ]
  return (
    <div>
      {loading ? (
        <Loading mode="dark" />
      ) : (
        <>
          <CategoryHeader />
          <Filter
            filterFields={filterFields}
            onFinish={(values) => console.log(values)}
          />
          <Container size="xxl">
            <div style={{ margin: '0 0 50px 0' }}>
              {/* <h3 className="jetbrains-mono" style={{ marginBottom: '20px' }}>
                  {category.name['uz']}
                </h3> */}
              <Row gutter={[20, 20]}>
                {data?.map((product) => (
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <ProductCard {...product} />
                  </Col>
                ))}
              </Row>
            </div>
          </Container>
        </>
      )}
      <GetInTouch />
    </div>
  )
}

export default Products

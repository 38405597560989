import { ICurrency } from 'common/types/currency'
import useCurrency from 'hooks/useCurrency'

export class Format {
  public money(amount: number = 0, currency: string | undefined) {
    const currencies = JSON.parse(
      localStorage.getItem('currencies') ?? '[]'
    ) as ICurrency[]
    const currencyCurrency = currencies.find((item) => item._id === currency)

    const newAmount = new Intl.NumberFormat('uz-UZ', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
      useGrouping: true
    }).format(amount)

    switch (currencyCurrency?.side) {
      case 'end':
        return `${newAmount} ${currencyCurrency.symbol ?? currencyCurrency.name}`
      case 'start':
        return `${currencyCurrency.symbol ?? currencyCurrency.name} ${newAmount}`
      default:
        return newAmount
    }
  }

  public number(
    number: number = 0,
    precision: number = 0,
    type: 'round' | 'ceil' | 'floor' = 'floor'
  ): string {
    return new Intl.NumberFormat('uz-UZ', {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
      useGrouping: true
    }).format(number)
  }
}

export const format = new Format()

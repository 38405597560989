import api from 'common/api'
import { ICategory } from 'common/types/category'
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'

const Context = () => {
  const [data, setData] = useState<ICategory[]>()
  const [loading, setLoading] = useState(true)
  const [isBasket, setIsBasket] = useState(false)
  const [isLoginModal, setIsLoginModal] = useState(false)

  const getData = async () => {
    try {
      setLoading(true)
      const response = await api.category.getAll()
      setData(response.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  return {
    state: { data, loading, isBasket, isLoginModal },
    actions: { setIsBasket, setIsLoginModal }
  }
}

const NavbarContext = createContext<any>({ state: {}, actions: {} })

export const NavbarContextProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const value = Context()
  return (
    <NavbarContext.Provider value={value}>{children}</NavbarContext.Provider>
  )
}

export default function useNavbarContext() {
  return useContext<ReturnType<typeof Context>>(NavbarContext)
}

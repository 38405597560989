import { ICurrency } from 'common/types/currency'
import useLocalStorageState from 'use-local-storage-state'

export default function useCurrency() {
  const [currencies, setCurrencies] = useLocalStorageState<ICurrency[]>(
    'currencies',
    {
      defaultValue: []
    }
  )

  const findCurrency = (id: string) => {
    return currencies.find((currency) => currency._id === id)
  }

  const mainCurrency = currencies[0]

  return { currencies, setCurrencies, findCurrency, mainCurrency }
}

import { Container } from 'components/ui'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import useProductsContext from '../services/productsContext'

const CategoryHeader = () => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const language = i18n.language as 'uz' | 'ru' | 'en'
  const {
    state: { category, data }
  } = useProductsContext()
  return (
    <div
      className="category-header"
      style={{
        backgroundImage: `url(${process.env.REACT_APP_FILE_URL}${category?.image.source_url})`
      }}
    >
      <Container size="xxl" style={{ textAlign: 'center' }}>
        <h1 className="cormorant-title">{category?.name[language]}</h1>
        <p>
          {data?.length || 0} {t('categories.available_products')}
        </p>
      </Container>
    </div>
  )
}

export default CategoryHeader

import api from 'common/api'
import { IProduct } from 'common/types/products'
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import { useParams } from 'react-router-dom'

const Context = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [product, setProduct] = useState<IProduct>()

  const getById = async (id: string) => {
    try {
      setLoading(true)
      const response = await api.product.getById(id)
      setProduct(response.data)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) getById(id)
  }, [id])

  return {
    state: { loading, product },
    actions: {}
  }
}

const ProductContext = createContext<any>({ state: {}, actions: {} })

export const ProductContextProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const value = Context()
  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  )
}

export default function useProductContext() {
  return useContext<ReturnType<typeof Context>>(ProductContext)
}

import {
  Button,
  Checkbox,
  Col,
  Divider,
  Flex,
  Form,
  FormProps,
  Grid,
  message,
  Modal,
  Row
} from 'antd'
import api from 'common/api'
import { Input, TextArea } from 'components/form'
import { Container } from 'components/ui'
import { motion } from 'framer-motion'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import LeftImage from 'assets/images/getintouch-left.png'
import RightImage from 'assets/images/getintouch-right.png'

interface IGetInTouchForm {
  name: string
  phone_number: string
  email: string
  text: string
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    margin: 0;
    height: ${(props) => props.height};
    width: 100%;
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
  }
  .ant-modal-footer {
    padding: 0;
    margin: 0;
  }
`

const { useBreakpoint } = Grid

const SubscribeModal = ({
  isNews,
  setIsNews
}: {
  isNews: boolean
  setIsNews: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const agree_watch = Form.useWatch('agree', form)
  const screens = useBreakpoint()
  const [loading, setLoading] = useState(false)

  const onFinish: FormProps['onFinish'] = async (values) => {
    try {
      setLoading(true)
      const response = await api.home.subscribe(values)
      message.success(t('get_in_touch.news.form.success'))
      form.resetFields()
      setIsNews(false)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <StyledModal
      open={isNews}
      onCancel={() => setIsNews(false)}
      footer={false}
      width={900}
      centered
    >
      <Row>
        <Col xs={24} md={14}>
          <img
            src="https://s3-alpha-sig.figma.com/img/9139/141a/cfc5a1529f907bd9371cba32f0f73713?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=JgltuUAsRQJxiZq1r8pI~IjdI1k9ubY0wo2pqwKypxh4u7xL0-M56VTCTsZCzByO5AiT3hUTjGDzmDz3CLRa0DeM7vl3I7fPdKuWuSz1pNnKYXNqnTwsf8QmvOM45ixgRsTL0Ori7mdaTyuT~9peM79U5bX6IWPsSqaPLizzw0GPGWZorUtYd34RgL4GBoKbI6Kd1upySg06ImgBg64wLfOU9paclq42YH5mVsszO1C5~Wf6y~EWYpl3a~QcOyVsorQsdE0FGdB5W-HZd8lvbItjmeYiRZ8iqhrnTWslsdJ-s-kyUPqIbfi27qAz1INChW6VhocCguIMbMH3p42pCw__"
            alt="/"
            style={{
              height:
                screens.xs || (screens.sm && !screens.md) ? '150px' : '500px',
              width: '100%',
              objectFit: 'cover',
              display: 'block'
            }}
          />
        </Col>
        <Col xs={24} md={10}>
          <div style={{ padding: '10px' }}>
            <h1 className="cormorant-sc-semibold">
              {t('get_in_touch.news.form.title')}
            </h1>
            <Form
              form={form}
              name="news"
              layout="vertical"
              style={{ marginTop: 40 }}
              onFinish={onFinish}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t('get_in_touch.form.email_message')
                  }
                ]}
                style={{ marginBottom: 10 }}
                name="email"
              >
                <Input placeholder="something@gmail.com" />
              </Form.Item>
              <Form.Item style={{ marginBottom: 10 }}>
                <Button
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={!agree_watch}
                >
                  {t('get_in_touch.form.submit')}
                </Button>
              </Form.Item>
              <Form.Item name="agree" valuePropName="checked">
                <Checkbox style={{ color: 'rgba(111, 111, 111, 1)' }}>
                  {t('get_in_touch.news.form.agree')}
                </Checkbox>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </StyledModal>
  )
}

const GetInTouch = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const screens = useBreakpoint()
  const [loading, setLoading] = useState(false)
  const [isNews, setIsNews] = useState(false)

  // Using useInView to trigger animations on scroll
  const [firstImageRef, firstImageInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const [secondImageRef, secondImageInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const [formRef, formInView] = useInView({ triggerOnce: true, threshold: 0.2 })
  const [subscribeRef, subscribeInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })

  const onFinish: FormProps['onFinish'] = async (values) => {
    try {
      setLoading(true)
      const response = await api.home.request(values)
      message.success(t('get_in_touch.form.success'))
      form.resetFields()
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        gap={30}
        vertical={!screens.md}
        style={{ margin: '50px 0', overflow: 'hidden' }}
      >
        {/* First Image - Slide from Left when in view */}
        <motion.img
          ref={firstImageRef}
          src={LeftImage}
          width={!screens.md ? '100%' : 340}
          height={!screens.md ? 300 : 500}
          style={{ objectFit: 'cover' }}
          initial={{ opacity: 0, x: -100 }}
          animate={firstImageInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 0.8 }}
        />

        {/* Middle Section - Slide from Top when in view */}
        <motion.div
          ref={formRef}
          style={{
            flexGrow: 1,
            margin: 'auto',
            maxWidth: screens.xl ? '100%' : '500px',
            padding: '0px 20px'
          }}
          initial={{ opacity: 0, y: 100 }}
          animate={formInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8 }}
        >
          <h1 className="cormorant-title" style={{ textAlign: 'center' }}>
            {t('get_in_touch.title')}
          </h1>
          <p
            className="jetbrains-mono"
            style={{ textAlign: 'center', color: 'rgba(153, 153, 153, 1)' }}
          >
            {t('get_in_touch.description')}
          </p>
          <Form
            form={form}
            name="get-in-touch"
            layout="vertical"
            style={{ marginTop: 40 }}
            onFinish={onFinish}
          >
            <Row gutter={20}>
              <Col xs={24} md={12}>
                <Form.Item<IGetInTouchForm>
                  rules={[
                    {
                      required: true,
                      message: t('get_in_touch.form.name_message')
                    }
                  ]}
                  style={{ marginBottom: 10 }}
                  name="name"
                >
                  <Input placeholder={t('get_in_touch.form.name')} />
                </Form.Item>
                <Form.Item<IGetInTouchForm>
                  rules={[
                    {
                      required: true,
                      message: t('get_in_touch.form.phone_number_message')
                    }
                  ]}
                  style={{ marginBottom: 10 }}
                  name="phone_number"
                >
                  <Input placeholder="+998 XX XXX-XX-XX" />
                </Form.Item>
                <Form.Item<IGetInTouchForm>
                  rules={[
                    {
                      required: true,
                      message: t('get_in_touch.form.email_message')
                    }
                  ]}
                  style={{ marginBottom: 10 }}
                  name="email"
                >
                  <Input placeholder="something@gmail.com" />
                </Form.Item>
                <Form.Item<IGetInTouchForm> style={{ marginBottom: 10 }}>
                  <Button
                    size="large"
                    block
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    {t('get_in_touch.form.submit')}
                  </Button>
                </Form.Item>
                <p
                  style={{
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    color: 'rgba(111, 111, 111, 1)'
                  }}
                >
                  {t('get_in_touch.form.submit_desc')}
                </p>
              </Col>
              <Col xs={24} md={12} style={{ height: '100%' }}>
                <Form.Item<IGetInTouchForm>
                  style={{ marginBottom: 10, height: '100%' }}
                  name="text"
                  rules={[
                    {
                      required: true,
                      message: t('get_in_touch.form.text_message')
                    }
                  ]}
                >
                  <TextArea
                    placeholder={t('get_in_touch.form.text')}
                    style={{ height: '100%' }}
                    rows={7}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </motion.div>

        {/* Second Image - Slide from Right when in view */}
        {screens.xl && (
          <motion.img
            ref={secondImageRef}
            src={RightImage}
            width={340}
            height={500}
            initial={{ opacity: 0, x: 100 }}
            animate={secondImageInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8 }}
          />
        )}
      </Flex>

      {/* Subscribe Section - Slide from Bottom when in view */}
      <motion.div
        ref={subscribeRef}
        initial={{ opacity: 0, y: 100 }}
        animate={subscribeInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        <Container size="xxl">
          <Divider />
        </Container>
        <Container style={{ textAlign: 'center', padding: '50px 0' }}>
          <h1 className="cormorant-title">{t('get_in_touch.news.title')}</h1>
          <p style={{ color: 'rgba(153, 153, 153, 1)' }}>
            {t('get_in_touch.news.description')}
          </p>
          <Button
            size="large"
            type="primary"
            shape="round"
            style={{ marginTop: 30 }}
            onClick={() => setIsNews(true)}
          >
            {t('get_in_touch.news.subscribe')}
          </Button>
        </Container>
        <Container size="xxl">
          <Divider />
        </Container>
      </motion.div>
      <SubscribeModal isNews={isNews} setIsNews={setIsNews} />
    </>
  )
}

export default GetInTouch

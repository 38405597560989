import { uMath } from 'utils/uMath'
import useCurrency from './useCurrency'

export default function useConverter() {
  const { currencies, findCurrency, mainCurrency } = useCurrency()

  function converter(
    value: number | undefined,
    sourceCurrency: string | undefined,
    destCurrency: string | undefined
  ) {
    try {
      if (sourceCurrency?.toString() === destCurrency?.toString()) {
        return value
      } else {
        if (
          typeof value !== 'undefined' &&
          !isNaN(value) &&
          sourceCurrency &&
          destCurrency
        ) {
          let source = findCurrency(sourceCurrency)
          let dest = source?.values?.find(
            (f) => f?.to_currency_id === destCurrency
          )

          if (dest)
            return uMath.divide(
              uMath.multiply(value, dest.numerator),
              dest.denominator
            )
          else return NaN
        } else {
          return NaN
        }
      }
    } catch (error) {
      console.error('Converter converter ~ ', error)
      return NaN
    }
  }

  function convertToMain(value: number, sourceCurrency: string) {
    const destCurrency = mainCurrency._id
    if (sourceCurrency?.toString() === destCurrency?.toString()) {
      return value
    } else {
      let source = findCurrency(sourceCurrency)
      let dest = source?.values?.find((f) => f?.to_currency_id === destCurrency)

      return Math.round(value * (dest ? dest?.value : 1) * 1000) / 1000
    }
  }

  function converterReal(
    value: number,
    sourceCurrency: string,
    destCurrency: string
  ) {
    if (sourceCurrency?.toString() === destCurrency?.toString()) {
      return value
    } else {
      let source = findCurrency(sourceCurrency)
      let dest = source?.values?.find((f) => f?.to_currency_id === destCurrency)

      return value * (dest ? dest?.value : 1)
    }
  }

  return { converter, convertToMain, converterReal }
}

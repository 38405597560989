import React from 'react'
import { ProductsContextProvider } from '../services/productsContext'
import Products from './Products'

const index = () => {
  return (
    <ProductsContextProvider>
      <Products />
    </ProductsContextProvider>
  )
}

export default index

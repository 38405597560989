import { ApiResponse } from 'common/types/common'
import { Api } from '../api'
import { ISize } from 'common/types/size'

export class SizeApi extends Api {
  async getAll() {
    return await this.execute<ApiResponse<ISize[]>>(
      'get',
      `size/get-all`,
      null,
      null,
      undefined
    )
  }
}

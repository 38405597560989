import { InputProps as AntInputProps, Input as AntInput } from 'antd'
import React from 'react'

interface InputProps extends AntInputProps {}

const Input: React.FC<InputProps> = (props) => {
  return (
    <AntInput
      {...props}
      size="large"
      style={{
        fontFamily: "'Nunito', sans-serif",
        fontWeight: '500',
        fontStyle: 'normal',
        ...props.style // Allows passing additional styles if needed
      }}
    />
  )
}

export default Input

import React from 'react'
import { OrderInfoContextProvider } from '../services/orderInfoContext'
import OrderInfo from './OrderInfo'

const index = () => {
  return (
    <OrderInfoContextProvider>
      <OrderInfo />
    </OrderInfoContextProvider>
  )
}

export default index

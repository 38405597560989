import React from 'react'
import Home from './Home'
import { HomeContextProvider } from '../services/homeContext'

const index = () => {
  return (
    <HomeContextProvider>
      <Home />
    </HomeContextProvider>
  )
}

export default index

import { Button, Form, FormProps, Space } from 'antd'
import React, { CSSProperties, useEffect, useState } from 'react'
import styled from 'styled-components'

interface IOption {
  id?: number
  label: React.ReactNode
  value: string
  isSelected?: boolean
  disabled?: boolean
}

interface IMultipleChoose {
  form?: FormProps['form']
  name?: string
  label?: string
  onClick?: (value: any) => void
  options: IOption[]
  multiple?: boolean
  buttonStyle?: CSSProperties
}

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label label {
    font-family: 'Nunito', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }
`

const MultipleChoose = ({
  label,
  options: initialOptions,
  form,
  name,
  onClick,
  multiple = true,
  buttonStyle
}: IMultipleChoose) => {
  const editedOptions = initialOptions.map((option, index) => ({
    ...option,
    id: index,
    isSelected: false
  }))
  const [options, setOptions] = useState<IOption[]>(editedOptions)
  const formValues = form?.getFieldValue(name)

  const handleChange = (id: number) => {
    const hasSelected = multiple
      ? options.map((option) =>
          option.id === id
            ? { ...option, isSelected: !option.isSelected }
            : option
        )
      : options.map((option) =>
          option.id === id
            ? { ...option, isSelected: true }
            : { ...option, isSelected: false }
        )
    setOptions(hasSelected)
    if (onClick) {
      let selectedValues: any[] | undefined = hasSelected
        ?.filter((option) => option.isSelected === true)
        .map((option) => option.value)

      selectedValues = selectedValues.length ? selectedValues : undefined
      onClick(selectedValues)
    }
  }

  useEffect(() => {
    if (name && form) {
      setOptions(
        options?.map((option) => {
          if (multiple) {
            return formValues?.includes(option.value)
              ? { ...option, isSelected: true }
              : option
          } else {
            return formValues?.includes(option.value)
              ? { ...option, isSelected: true }
              : { ...option, isSelected: false }
          }
        })
      )
    }
  }, [formValues])
  return (
    <StyledFormItem name={name} style={{ margin: 0 }} label={label}>
      <Space style={{ width: '100%' }} wrap>
        {options?.map((option) => (
          <Button
            onClick={() => handleChange(option.id!)}
            type={option.isSelected ? 'primary' : 'default'}
            // icon={option.isSelected && <FontAwesomeIcon icon={faCheck} />}
            style={buttonStyle}
            disabled={option.disabled}
          >
            {option.label}
          </Button>
        ))}
      </Space>
    </StyledFormItem>
  )
}

export default MultipleChoose

import React, { useEffect, useMemo, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
// import 'swiper/swiper-bundle.min.css'
import './ProductCard.css'
import { IProduct } from 'common/types/products'
import { Button, Flex, message, Space } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import {
  faShoppingBasket,
  faHeart as filledHeard
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { format } from 'utils/format'
import useLayoutContext from '../layout/services/layoutContext'
import { Link } from 'react-router-dom'
import useLocalStorageState from 'use-local-storage-state'
import useConverter from 'hooks/useConverter'

const ProductCard: React.FC<IProduct> = (product) => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const language = i18n.language as 'uz' | 'ru' | 'en'
  const { converter } = useConverter()

  const [selectedSize, setSelectedSize] = useState<string | null>(null)
  const [selectedColor, setSelectedColor] = useState<string | null>(null)
  const [like, setLike] = useState(false)

  const [basket, setBasket] = useLocalStorageState<IProduct[]>('basket', {
    defaultValue: []
  })
  const [wishlist, setWishlist] = useLocalStorageState<IProduct[]>('wishlist', {
    defaultValue: []
  })

  const current = useMemo(
    () =>
      basket?.find(
        (item) =>
          item._id === product?._id &&
          item.selected_size === selectedSize &&
          item.selected_color === selectedColor
      ),
    [basket, product, selectedColor, selectedSize]
  )

  const productIntegration = useMemo(
    () =>
      product?.integration?.find((product) =>
        selectedSize && selectedColor
          ? product.size_id === selectedSize &&
            product.color_id === selectedColor
          : true
      ),
    [selectedColor, selectedSize]
  )

  const handleLike = () => {
    setLike((prev) => {
      if (prev) {
        setWishlist(wishlist?.filter((wish) => wish._id !== product?._id))
        return false
      } else {
        setWishlist([...wishlist, product])
        return true
      }
    })
  }

  const selectColor = (color: string) => {
    setSelectedColor((prev) => {
      if (prev === color) {
        // Remove product from the basket if the same color is selected again
        // setBasket(basket.filter((item) => item._id !== product?._id))
        // message.error('Savatdan olib tashlandi')
        setSelectedSize(null)
        return color
      } else {
        // const selectedSize = basket.find(
        //   (item) => item._id === product?._id
        // )?.selected_size

        // Set size if it's not already selected
        setSelectedSize((prevSize) =>
          prevSize ? prevSize : product?.sizes?.[0]._id
        )

        // setBasket(
        //   current
        //     ? basket.map((item) =>
        //         item._id === product?._id
        //           ? {
        //               ...item,
        //               selected_color: color,
        //               ...(!selectedSize && {
        //                 selected_size: product?.sizes?.[0]._id ?? null
        //               })
        //             }
        //           : item
        //       )
        //     : [
        //         ...basket,
        //         {
        //           ...product,
        //           selected_color: color,
        //           selected_size: product?.sizes?.[0]?._id ?? null,
        //           count: 1
        //         }
        //       ]
        // )
        // !current && message.success("Savatga qo'shildi")
        return color
      }
    })
  }

  const selectSize = (size: string) => {
    setSelectedSize((prev) => {
      if (prev === size) {
        // Remove product from the basket if the same size is selected again
        // setBasket(basket.filter((item) => item._id !== product?._id))
        // setSelectedColor(null) // Reset color when size is deselected
        return size
      } else {
        setSelectedColor((prev) => (prev ? prev : product?.colors?.[0]?._id))

        // setBasket(
        //   current
        //     ? basket.map((item) =>
        //         item._id === product?._id
        //           ? {
        //               ...item,
        //               selected_size: size,
        //               ...(!selectedColor && {
        //                 selected_color: product?.colors?.[0]._id
        //               })
        //             }
        //           : item
        //       )
        //     : [
        //         ...basket,
        //         {
        //           ...product,
        //           selected_size: size,
        //           selected_color: product?.colors?.[0]._id,
        //           count: 1
        //         }
        //       ]
        // )
        // !current && message.success("Savatga qo'shildi")
        return size
      }
    })
  }

  const addToBasket = () => {
    if (current) {
      setBasket(
        basket.filter(
          (item) =>
            !(
              item._id === product?._id &&
              item.selected_size === selectedSize &&
              item.selected_color === selectedColor
            )
        )
      )
      setSelectedColor(null)
      setSelectedSize(null)
    } else {
      if (selectedSize && selectedColor)
        setBasket([
          ...basket,
          {
            ...product,
            selected_color: selectedColor,
            selected_size: selectedSize,
            count: 1
          }
        ])
    }
  }

  useEffect(() => {
    const isBasketAvailable = basket.find((item) => item._id === product?._id)
    const isWishlistAvailable = wishlist.find(
      (item) => item._id === product?._id
    )

    if (isBasketAvailable) {
      if (isBasketAvailable.selected_color) {
        setSelectedColor(isBasketAvailable.selected_color)
      }
      if (isBasketAvailable.selected_size) {
        setSelectedSize(isBasketAvailable.selected_size)
      }
    }

    if (isWishlistAvailable) setLike(true)
  }, [])

  const {
    state: { currency }
  } = useLayoutContext()

  return (
    <Space className="product-card" direction="vertical">
      <Button
        icon={<FontAwesomeIcon icon={like ? filledHeard : faHeart} />}
        shape="circle"
        type="text"
        className="product-card-like"
        size="large"
        styles={{
          icon: {
            color: like ? 'rgba(247, 0, 0, 1)' : 'white'
          }
        }}
        onClick={() => handleLike()}
      />
      <div className="main-content">
        <Swiper spaceBetween={0} slidesPerView={1} loop grabCursor={true}>
          <SwiperSlide>
            <img
              src={`${process.env.REACT_APP_FILE_URL}${product?.main_image.source_url}`}
              alt={product?.main_image.alt}
            />
          </SwiperSlide>
          {product?.images
            ?.filter((image) =>
              selectedColor ? image.color_id === selectedColor : image
            )
            ?.map((src, index) => (
              <SwiperSlide key={index}>
                <img
                  src={`${process.env.REACT_APP_FILE_URL}${src.source_url}`}
                  alt={src.alt}
                />
              </SwiperSlide>
            ))}
        </Swiper>
        {product?.sizes?.length > 0 && (
          <>
            <div className={`size-selector ${current ? 'active' : ''}`}>
              <p>{t('common.select_size')}:</p>
              <div className="size-selector-items">
                {product?.sizes.map((size) => (
                  <button
                    key={size._id}
                    onClick={() => selectSize(size._id)}
                    className={selectedSize === size._id ? 'active' : ''}
                  >
                    {size.code}
                  </button>
                ))}
              </div>
              {selectedSize && (
                <Button
                  type="primary"
                  style={{
                    marginTop: 10,
                    borderRadius: 0,
                    ...(current && { backgroundColor: 'rgba(40, 205, 65, 1)' })
                  }}
                  block
                  onClick={addToBasket}
                >
                  {current ? (
                    <>{t('products.added_to_basket')}</>
                  ) : (
                    <>
                      {t('products.add_to_basket')}{' '}
                      <FontAwesomeIcon icon={faShoppingBasket} />
                    </>
                  )}
                </Button>
              )}
            </div>
          </>
        )}
      </div>

      <Link to={`/product/${product._id}`}>
        <p style={{ color: 'rgba(111, 111, 111, 1)' }}>
          {product?.name[language]}
        </p>
      </Link>
      <p>
        {productIntegration
          ? format.money(
              converter(
                productIntegration?.price,
                productIntegration?.currency_id,
                currency
              ),
              currency
            )
          : t('products.no_price')}
      </p>

      {product?.colors?.length > 0 && (
        <Flex className="color-selector" align="center" gap={10}>
          {product?.colors.map((color) => (
            <button
              key={color._id}
              onClick={() => selectColor(color._id)}
              className={selectedColor === color._id ? 'active' : ''}
              style={{
                background: color?.image?.source_url
                  ? `url(${color?.image?.source_url})`
                  : color.hex_code
              }}
            />
          ))}
        </Flex>
      )}
    </Space>
  )
}

export default ProductCard

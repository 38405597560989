import styled from 'styled-components'

interface ContainerProps {
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'fluid'
}

const breakpoints: { [key in NonNullable<ContainerProps['size']>]: string } = {
  sm: '540px',
  md: '720px',
  lg: '960px',
  xl: '1140px',
  xxl: '1320px',
  fluid: '100%'
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: ${({ size }) => breakpoints[size || 'fluid']};

  ${({ size }) =>
    size !== 'fluid' &&
    size &&
    `
    @media (min-width: ${Object.keys(breakpoints).find(
      (key) =>
        breakpoints[key as keyof typeof breakpoints] === breakpoints[size]
    )}) {
      max-width: ${breakpoints[size]};
    }
  `}
  overflow: hidden
`

export default Container

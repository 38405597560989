import {
  faChevronLeft,
  faChevronRight,
  faPause,
  faPlay
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Flex, Space } from 'antd'
import { Container } from 'components/ui'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SwiperCore from 'swiper'
import 'swiper/css/effect-coverflow'
import { Autoplay, EffectCoverflow } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import '../styles/Carousel.css'
import Slider1 from 'assets/images/slider1.png'
import Slider2 from 'assets/images/slider2.png'
import Slider3 from 'assets/images/slider3.png'

const Carousel = () => {
  const swiperRef = useRef<SwiperCore | null>(null)
  const progressLine = useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation()

  const [isActive, setIsActive] = useState(true)

  const next = () => {
    swiperRef?.current?.slideNext()
  }

  const prev = () => {
    swiperRef?.current?.slidePrev()
  }

  const handleStart = () => {
    setIsActive(true)
    // swiperRef.current?.autoplay.start()
    swiperRef.current?.autoplay.resume()
  }

  const handleStop = () => {
    setIsActive(false)
    swiperRef.current?.autoplay.pause()
  }

  const onAutoplayTimeLeft = (s: any, time: number, progress: number) => {
    let width = 100 - Math.round(progress * 100)
    progressLine?.current?.style.setProperty(
      'width',
      `${width > 100 ? 100 : width}%`
    )
  }
  return (
    <div
      style={{ margin: '70px 0', marginBottom: '100px', overflow: 'hidden' }}
    >
      <Container size="md">
        <h2 className="jetbrains-mono" style={{ textAlign: 'center' }}>
          {t('about.carousel.title')}
        </h2>
      </Container>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true
        }}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false
        }}
        modules={[EffectCoverflow, Autoplay]}
        className="carousel-swiper"
        onSwiper={(swiper: any) => {
          swiperRef.current = swiper
        }}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
      >
        <SwiperSlide className="carousel-swiper-item">
          <img src={Slider1} />
        </SwiperSlide>
        <SwiperSlide className="carousel-swiper-item">
          <img src={Slider2} />
        </SwiperSlide>
        <SwiperSlide className="carousel-swiper-item">
          <img src={Slider3} />
        </SwiperSlide>
      </Swiper>
      <Flex
        className="carousel-swiper-actions"
        style={{
          width: '300px',
          margin: 'auto',
          marginTop: '-30px',
          zIndex: 4,
          position: 'relative'
        }}
        align="center"
        gap={10}
        vertical
      >
        <div
          style={{
            height: '4px',
            width: '100%',
            backgroundColor: 'rgba(233, 233, 233, 1)',
            position: 'relative'
          }}
        >
          <div
            ref={progressLine}
            style={{
              height: '100%',
              backgroundColor: 'black',
              transition: 'width 0.2s ease-in-out'
            }}
          ></div>
        </div>
        <Space>
          <Button
            onClick={prev}
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            shape="circle"
            type="primary"
            styles={{ icon: { color: 'black' } }}
            style={{
              background: 'transparent',
              border: '2px solid black'
            }}
          />
          <Button
            onClick={isActive ? handleStop : handleStart}
            icon={<FontAwesomeIcon icon={isActive ? faPause : faPlay} />}
            shape="circle"
            type="primary"
            styles={{ icon: { color: 'black' } }}
            style={{
              background: 'transparent',
              border: '2px solid black'
            }}
          />
          <Button
            onClick={next}
            icon={<FontAwesomeIcon icon={faChevronRight} />}
            shape="circle"
            type="primary"
            styles={{ icon: { color: 'black' } }}
            style={{
              background: 'transparent',
              border: '2px solid black'
            }}
          />
        </Space>
      </Flex>
      <Container size="md">
        <h2
          className="jetbrains-mono"
          style={{
            textAlign: 'center',
            marginTop: '50px',
            color: 'rgba(111, 111, 111, 1)'
          }}
        >
          {t('about.carousel.description')}
        </h2>
      </Container>
    </div>
  )
}

export default Carousel

import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Grid, Spin } from 'antd'
import { Input } from 'components/form'
import { StyledSearchModal } from 'components/ui/navbar/styles/Navbar.styles'
import { useState, useTransition } from 'react'
import { Link } from 'react-router-dom'
import useSearchContext from '../services/searchContext'
import { useTranslation } from 'react-i18next'
import notFound from 'assets/images/notfound.svg'

const { useBreakpoint } = Grid

const Search = () => {
  const { t } = useTranslation()
  const screens = useBreakpoint()
  const [isOpen, setIsOpen] = useState(false)
  const { i18n } = useTranslation()
  const language = i18n.language as 'uz' | 'ru' | 'en'

  const {
    state: { search, loading, products },
    actions: { handleSearch }
  } = useSearchContext()
  return (
    <>
      {(screens.sm === true && screens.md === false) || screens.xs ? (
        <Button
          icon={<FontAwesomeIcon icon={faSearch} />}
          shape="circle"
          type="text"
          onClick={() => setIsOpen(true)}
          size="large"
        />
      ) : (
        <Button
          icon={<FontAwesomeIcon icon={faSearch} />}
          shape="circle"
          type="text"
          onClick={() => setIsOpen(true)}
        />
      )}
      <StyledSearchModal
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={false}
        closeIcon={false}
      >
        <Input
          size="large"
          suffix={<FontAwesomeIcon icon={faSearch} />}
          style={{ width: '100%' }}
          className="search-input"
          onChange={handleSearch}
          value={search}
        />
        <div
          className="search-results"
          style={{ maxHeight: 500, overflowY: 'auto' }}
        >
          {loading ? (
            <div style={{ padding: '30px 0', textAlign: 'center' }}>
              <Spin />
            </div>
          ) : products ? (
            products?.length > 0 ? (
              products?.map((product) => (
                <Link
                  to={`/product/${product._id}`}
                  className="jetbrains-mono"
                  onClick={() => setIsOpen(false)}
                >
                  {product.name[language]}
                </Link>
              ))
            ) : (
              <div style={{ padding: '30px 0', textAlign: 'center' }}>
                <img src={notFound} width={150} />
                <p>{t('common.not_found')}</p>
              </div>
            )
          ) : null}
        </div>
      </StyledSearchModal>
    </>
  )
}

export default Search

import React from 'react'
import { ProfileContextProvider } from '../services/profileContext'
import Profile from './Profile'

const index = () => {
  return (
    <ProfileContextProvider>
      <Profile />
    </ProfileContextProvider>
  )
}

export default index

import React from "react";
import { FilterContextProvider } from "./context/useFilter";
import Filter, { IFilter } from "./Filter";

const index = <FieldType,>(props: IFilter<FieldType>) => {
  return (
    <FilterContextProvider>
      <Filter {...props} />
    </FilterContextProvider>
  );
};

export default index;

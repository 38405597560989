import { Container } from 'components/ui'
import '../styles/Gallery.css'
import { Col, Row } from 'antd'
import Gallery1 from 'assets/images/gallery-1.png'
import Gallery2 from 'assets/images/gallery-2.png'
import Gallery3 from 'assets/images/gallery-3.png'
import Gallery4 from 'assets/images/gallery-4.png'
import Gallery5 from 'assets/images/gallery-5.png'

const Gallery = () => {
  return (
    <div className="about-gallery" style={{ overflow: 'hidden' }}>
      <Container size="md">
        <Row gutter={[20, 20]}>
          <Col xs={12} md={12} className="top-images">
            <img src={Gallery1} />
          </Col>
          <Col xs={12} md={12} className="top-images">
            <img src={Gallery2} />
          </Col>

          <Col xs={8} md={8} className="bottom-images">
            <img src={Gallery3} />
          </Col>
          <Col xs={8} md={8} className="bottom-images">
            <img src={Gallery4} />
          </Col>
          <Col xs={8} md={8} className="bottom-images">
            <img src={Gallery5} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Gallery

import { Input as AntInput } from 'antd'
import { TextAreaProps } from 'antd/es/input'
import React from 'react'

const TextArea: React.FC<TextAreaProps> = (props) => {
  return (
    <AntInput.TextArea
      {...props}
      size="large"
      style={{
        fontFamily: "'Nunito', sans-serif",
        fontWeight: '500',
        fontStyle: 'normal',
        ...props.style // Allows passing additional styles if needed
      }}
    />
  )
}

export default TextArea

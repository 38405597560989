import { NavbarContextProvider } from '../services/navbarContext'
import Navbar from './Navbar'

const index = () => {
  return (
    <NavbarContextProvider>
      <Navbar />
    </NavbarContextProvider>
  )
}

export default index

import api from 'common/api'
import { IColor } from 'common/types/color'
import { ISize } from 'common/types/size'
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import { useParams } from 'react-router-dom'

const Context = () => {
  const { id } = useParams()
  const [colors, setColors] = useState<IColor[]>()
  const [sizes, setSizes] = useState<ISize[]>()

  const getColors = async () => {
    try {
      const response = await api.color.getAll()
      setColors(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  const getSizes = async () => {
    try {
      const response = await api.size.getAll()
      setSizes(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  const requestAll = async () => {
    try {
      await getColors()
      await getSizes()
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    requestAll()
  }, [id])
  return {
    state: { sizes, colors },
    actions: {}
  }
}

const ProfileContext = createContext<any>({ state: {}, actions: {} })

export const ProfileContextProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const value = Context()
  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  )
}

export default function useProfileContext() {
  return useContext<ReturnType<typeof Context>>(ProfileContext)
}

import api from 'common/api'
import { IProduct } from 'common/types/products'
import { debounce } from 'lodash'
import React, {
  ChangeEvent,
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

const Context = () => {
  const [search, setSearch] = useState<string>()
  const [products, setProducts] = useState<IProduct[]>()
  const [loading, setLoading] = useState(false)

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const getSearchProduct = async (search: string) => {
    try {
      setLoading(true)
      const response = await api.product.getChoose({
        limit: 1000,
        page: 1,
        search: search
      })
      setProducts(response.data.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const debouncedGetSearchProduct = useCallback(
    debounce((searchTerm: string) => getSearchProduct(searchTerm), 300),
    []
  )

  useEffect(() => {
    if (search) debouncedGetSearchProduct(search)
    return () => {
      debouncedGetSearchProduct.cancel()
    }
  }, [search, debouncedGetSearchProduct])

  return {
    state: { search, products, loading },
    actions: { handleSearch }
  }
}

const SearchContext = createContext<any>({ state: {}, actions: {} })

export const SearchContextProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const value = Context()
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}

export default function useSearchContext() {
  return useContext<ReturnType<typeof Context>>(SearchContext)
}

import { motion } from 'framer-motion'
import { Divider } from 'antd'
import { ContactAndLocations, GetInTouch } from 'components/pages'
import { Container, Loading } from 'components/ui'
import Categories from '../components/Categories'
import Collection from '../components/Collection'
import GetTheLook from '../components/GetTheLook'
import SliderWithControls from '../components/SliderWithDuration'
import VideoContent from '../components/VideoContent'
import useHomeContext from '../services/homeContext'
import { useInView } from 'react-intersection-observer'
import '../styles/Home.css'
import ScrollToTopWithTransition from 'utils/ScrollToTopWithTransition'
import Videos from '../components/Videos'

const Home = () => {
  const {
    state: { data, loading }
  } = useHomeContext()

  // Set default animation settings
  const animationSettings = {
    opacity: 0,
    y: 100,
    transition: { duration: 2 }
  }

  // Add intersection observer for each section
  const [sliderRef, sliderInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const [videosRef, videosInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const [categoriesRef, categoriesInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const [collectionRef, collectionInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const [getTheLookRef, getTheLookInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const [videoContentRef, videoContentInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const [getInTouchRef, getInTouchInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const [contactRef, contactInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })

  return (
    <motion.div
      className="home"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {data && !loading ? (
        <>
          <ScrollToTopWithTransition />
          <motion.div
            ref={sliderRef}
            initial={animationSettings}
            animate={sliderInView ? { opacity: 1, y: 0 } : animationSettings}
            transition={{ duration: 0.8 }}
          >
            <SliderWithControls items={data?.page_content} />
          </motion.div>
          <motion.div
            ref={videosRef}
            initial={animationSettings}
            animate={videosInView ? { opacity: 1, y: 0 } : animationSettings}
            transition={{ duration: 0.8 }}
          >
            <Videos />
          </motion.div>
          <motion.div
            ref={categoriesRef}
            initial={animationSettings}
            animate={
              categoriesInView ? { opacity: 1, y: 0 } : animationSettings
            }
            transition={{ duration: 0.8 }}
          >
            <Categories />
          </motion.div>
          <motion.div
            ref={collectionRef}
            initial={animationSettings}
            animate={
              collectionInView ? { opacity: 1, y: 0 } : animationSettings
            }
            transition={{ duration: 0.8 }}
          >
            <Collection />
          </motion.div>
          <motion.div
            ref={getTheLookRef}
            initial={animationSettings}
            animate={
              getTheLookInView ? { opacity: 1, y: 0 } : animationSettings
            }
            transition={{ duration: 0.8 }}
          >
            <GetTheLook />
          </motion.div>
          <motion.div
            ref={videoContentRef}
            initial={animationSettings}
            animate={
              videoContentInView ? { opacity: 1, scale: 1 } : animationSettings
            }
            transition={{ duration: 0.8 }}
          >
            <VideoContent />
          </motion.div>
          <Container size="xxl">
            <Divider />
          </Container>
          <motion.div
            ref={getInTouchRef}
            initial={animationSettings}
            animate={
              getInTouchInView ? { opacity: 1, y: 0 } : animationSettings
            }
            transition={{ duration: 0.8 }}
          >
            <GetInTouch />
          </motion.div>
          <motion.div
            ref={contactRef}
            initial={animationSettings}
            animate={contactInView ? { opacity: 1, y: 0 } : animationSettings}
            transition={{ duration: 0.8 }}
          >
            <ContactAndLocations />
          </motion.div>
        </>
      ) : (
        <Loading mode="dark" />
      )}
    </motion.div>
  )
}

export default Home

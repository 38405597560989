import { Container } from 'components/ui'
import '../styles/Advantages.css'
import { useTranslation } from 'react-i18next'
import AboutFirst from 'assets/images/about-first.png'
import AboutSecond from 'assets/images/about-second.png'
import AboutThird from 'assets/images/about-third.png'

const Advatanges = () => {
  const { t } = useTranslation()
  return (
    <div>
      <div
        style={{
          backgroundColor: 'black',
          padding: '10px 0px 70px 0',
          overflow: 'hidden'
        }}
      >
        <Container size="md">
          <div className="triple-image">
            <img
              src={AboutFirst}
              width={'300px'}
              height={'430px'}
              className="first"
            />
            <img
              src={AboutSecond}
              width={'280px'}
              height={'410px'}
              className="second"
            />
            <img
              src={AboutThird}
              width={'270px'}
              height={'380px'}
              className="third"
            />
          </div>
          <p style={{ textAlign: 'center', color: 'white', marginTop: '50px' }}>
            {t('about.advantages')}
          </p>
        </Container>
      </div>
    </div>
  )
}

export default Advatanges

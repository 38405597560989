import React, { useEffect, useRef, useState } from 'react'
import SwiperCore from 'swiper'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide, SwiperSlideProps } from 'swiper/react'

import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Flex } from 'antd'
import { IPageContent } from 'common/types/home'
import { Container } from 'components/ui'
import { useTranslation } from 'react-i18next'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Link } from 'react-router-dom'

const CustomSwiperSlide: React.FC<{
  item: IPageContent
  [key: string]: any
}> = ({
  item,
  isActive,
  handleStop,
  handleStart,
  width,
  activeIndex,
  length
}) => {
  const { i18n } = useTranslation()
  const language = i18n.language as 'uz' | 'ru' | 'en'

  const progressLine = useRef<HTMLDivElement | null>(null)
  const countContent = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    progressLine?.current?.style.setProperty('width', `${width}%`)
    if (countContent.current) {
      countContent.current.innerHTML = `${activeIndex} / ${length}`
    }
  }, [width, activeIndex])

  return (
    <Flex vertical>
      <div style={{ position: 'relative' }}>
        <Link
          to={
            item.page_direction === 'category'
              ? `/categories/${item.direction_id}`
              : `/product/${item.direction_id}`
          }
        >
          <img
            src={`${process.env.REACT_APP_FILE_URL}${item.value_url.source_url}`}
            alt={item.value_url.alt}
            style={{
              height: 'calc(100vh - 342px)',
              width: '100%',
              objectFit: 'cover',
              display: 'block'
            }}
          />
        </Link>
        <Flex
          className="swiper-actions"
          style={{ position: 'absolute', bottom: 30, right: 30 }}
          align="center"
          gap={20}
        >
          <h3
            ref={countContent}
            style={{ color: 'white', margin: 0, padding: 0 }}
            className="jetbrains-mono"
          ></h3>
          <Button
            onClick={isActive ? handleStop : handleStart}
            icon={<FontAwesomeIcon icon={isActive ? faPause : faPlay} />}
            shape="circle"
            type="default"
            styles={{ icon: { color: 'white' } }}
            style={{
              backgroundColor: 'transparent',
              borderColor: 'white',
              borderWidth: 3
            }}
            size="large"
          />
        </Flex>
      </div>
      <div
        style={{
          height: '4px',
          width: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.4)',
          position: 'relative'
        }}
      >
        <div
          ref={progressLine}
          style={{
            height: '100%',
            backgroundColor: '#fff',
            transition: 'width 0.2s ease-in-out'
          }}
        ></div>
      </div>
      <Container
        size="xxl"
        style={{ paddingBottom: '50px', paddingTop: '40px' }}
      >
        <h1
          style={{ color: 'white', fontSize: '60px', marginBottom: 10 }}
          className="cormorant-title"
        >
          {item.title[language]}
        </h1>
        <p style={{ color: 'white' }}>{item.description[language]}</p>
      </Container>
    </Flex>
  )
}

const SliderWithControls: React.FC<{ items: IPageContent[] }> = ({ items }) => {
  const swiperRef = useRef<SwiperCore | null>(null)

  const [isActive, setIsActive] = useState(true)
  const [width, setWidth] = useState<number>()
  const [activeIndex, setActiveIndex] = useState<number>()

  const handleStart = () => {
    setIsActive(true)
    swiperRef.current?.autoplay.resume()
  }

  const handleStop = () => {
    setIsActive(false)
    swiperRef.current?.autoplay.pause()
  }

  const onAutoplayTimeLeft = (s: any, time: number, progress: number) => {
    let width = 100 - Math.round(progress * 100)
    setWidth(width)
    setActiveIndex(s.activeIndex + 1)
  }

  return (
    <div style={{ backgroundColor: 'black' }}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false
        }}
        onSwiper={(swiper: any) => {
          swiperRef.current = swiper
        }}
        onSlideChange={() => {
          setIsActive(true)
        }}
        modules={[Autoplay]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        centeredSlides={true}
        style={{ margin: 0, padding: 0 }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <CustomSwiperSlide
              item={item}
              isActive={isActive}
              handleStop={handleStop}
              handleStart={handleStart}
              width={width}
              activeIndex={activeIndex}
              length={items.length}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default SliderWithControls

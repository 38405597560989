import { ConfigProvider } from 'antd'
import { Layout } from 'components/ui'
import useAuth from 'hooks/useAuth'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import {
  About,
  Categories,
  Cooperation,
  Home,
  Info,
  Looks,
  OrderCreate,
  OrderInfo,
  Product,
  Products,
  Profile,
  Purchases,
  Wishlist
} from 'routes'
import './App.css'

function App() {
  const { auth } = useAuth()
  const route = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '',
          element: <Home />
        },
        {
          path: 'cooperation',
          element: <Cooperation />
        },
        {
          path: 'about',
          element: <About />
        },
        {
          path: 'categories/:id',
          element: <Categories />
        },
        {
          path: 'product/:id',
          element: <Product />
        },
        {
          path: 'products/:id',
          element: <Products />
        },
        {
          path: 'looks/:id',
          element: <Looks />
        },
        {
          path: 'wishlist',
          element: <Wishlist />
        },
        {
          path: 'order',
          children: [
            {
              path: 'create',
              element: <OrderCreate />
            },
            {
              path: 'info/:id',
              element: <OrderInfo />
            }
          ]
        },
        ...(auth
          ? [
              {
                path: 'profile',
                element: <Profile />,
                children: [
                  {
                    path: 'info',
                    element: <Info />
                  },
                  {
                    path: 'purchases',
                    element: <Purchases />
                  }
                ]
              }
            ]
          : [])
      ]
    }
  ])
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#000000',
          colorBgContainerDisabled: 'rgb(246, 245, 247)'
        },
        components: {
          Button: {
            colorText: '#000000',
            colorPrimary: '#000000',
            algorithm: true,
            borderRadius: 8
          },
          Segmented: {
            itemSelectedBg: '#000000',
            itemSelectedColor: 'white'
          }
        }
      }}
      // locale={getLocales(i18n.language)}
    >
      <RouterProvider router={route} />
    </ConfigProvider>
  )
}

export default App

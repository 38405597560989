import { Container } from 'components/ui'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import useCategoriesContext from 'routes/categories/services/categoriesContext'

const CategoryHeader = () => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const language = i18n.language as 'uz' | 'ru' | 'en'
  const {
    state: { data }
  } = useCategoriesContext()
  return (
    <div
      className="category-header"
      style={{
        backgroundImage: `url(${process.env.REACT_APP_FILE_URL}${data?.category.image.source_url})`
      }}
    >
      <Container size="xxl" style={{ textAlign: 'center' }}>
        <h1 className="cormorant-title">{data?.category.name[language]}</h1>
        <p>
          {data?.data?.length || 0} {t('categories.available_products')}
        </p>
      </Container>
    </div>
  )
}

export default CategoryHeader

import Search from './Search'
import { SearchContextProvider } from '../services/searchContext'

const index = () => {
  return (
    <SearchContextProvider>
      <Search />
    </SearchContextProvider>
  )
}

export default index

import { ApiPaging, ApiResponse, paramApi } from 'common/types/common'
import { Api } from '../api'
import { IProduct } from 'common/types/products'

export class ProductApi extends Api {
  async getPaging(payload: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<IProduct[]>>>(
      'post',
      `product/paging`,
      payload,
      null,
      undefined
    )
  }
  async getChoose(payload: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<IProduct[]>>>(
      'post',
      `product/choose`,
      payload,
      null,
      undefined
    )
  }
  async getById(id: string) {
    return await this.execute<ApiResponse<IProduct>>(
      'post',
      `product/get-by-id/${id}`,
      null,
      null,
      undefined
    )
  }
}

import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import { Col, Row } from 'antd'
import { Container } from 'components/ui'
import { useTranslation } from 'react-i18next'
import useHomeContext from '../services/homeContext'

const CategoryItem = ({ category, language, index }: any) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  })

  const animationSettings = {
    initial: { opacity: 0, x: index > 1 ? 100 : -100 }, // Start from left
    animate: { opacity: 1, x: 0 }, // Move to original position
    transition: { duration: 0.8, delay: index * 0.1 } // Duration of animation, with delay
  }

  return (
    <motion.div
      ref={ref}
      initial={animationSettings.initial}
      animate={inView ? animationSettings.animate : animationSettings.initial}
      transition={animationSettings.transition}
    >
      <div className="categories-item">
        <Link to={`/categories/${category._id}`}>
          <div className="overlay"></div>
          <img
            src={process.env.REACT_APP_FILE_URL + category.image.source_url}
            alt={category.image.alt}
            style={{ display: 'block' }}
          />
          <div className="content">
            <h1 className="cormorant-sc-medium">{category.name[language]}</h1>
          </div>
        </Link>
      </div>
    </motion.div>
  )
}

const Categories = () => {
  const { i18n } = useTranslation()
  const language = i18n.language as 'uz' | 'ru' | 'en'
  const {
    state: { data }
  } = useHomeContext()

  return (
    <Container size="xxl" className="categories">
      <Row gutter={[0, 0]}>
        {data?.categories.map((category, index) => (
          <Col xs={12} md={6} key={category._id}>
            <CategoryItem
              category={category}
              language={language}
              index={index}
            />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default Categories

import api from 'common/api'
import useCurrency from 'hooks/useCurrency'
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

const Context = () => {
  const navRef = useRef<HTMLDivElement | null>(null)
  const { i18n } = useTranslation()
  const [language, setLanguage] = useState<'uz' | 'ru' | 'en'>(
    i18n.language as 'uz' | 'en' | 'ru'
  )
  const { currencies, setCurrencies } = useCurrency()
  const [currency, setCurrency] = useState<string>(
    localStorage.getItem('currency') ?? ''
  )

  const handleCurrency = (currency: string) => {
    setCurrency(currency)
    localStorage.setItem('currency', currency)
  }

  const handleLanguage = (language: 'uz' | 'ru' | 'en') => {
    setLanguage(language)
    i18n.changeLanguage(language)
    localStorage.setItem('language', language)
  }

  const getCurrencies = async () => {
    try {
      const response = await api.currency.getAll()
      setCurrencies(response.data)
      if (!currency) setCurrency(response.data[0]._id)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getCurrencies()
  }, [])

  return {
    state: { language, currency, navRef, currencies },
    actions: { handleCurrency, handleLanguage }
  }
}

const LayoutContext = createContext<any>({ state: {}, actions: {} })

export const LayoutContextProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const value = Context()
  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  )
}

export default function useLayoutContext() {
  return useContext<ReturnType<typeof Context>>(LayoutContext)
}
